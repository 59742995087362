import React from 'react';
import { useI18n } from 'services/i18n/hook';
import Card from 'components/ui/card';
import EmbedBuilderContainer from 'components/embed_builder/container';

const EmbedBuilderCard = () => {
  const { t } = useI18n();
  return (
    <Card title={<h5>{t('components.embed_builder.card.configure_embed')}</h5>}>
      <EmbedBuilderContainer />
    </Card>
  );
};

export default EmbedBuilderCard;
