import React from 'react';
import WorkflowTester from 'components/workflow/tester';
import FileProvider from 'services/file/provider';

const ScenesWorkflowTester = () => {
  return (
    <FileProvider>
      <WorkflowTester />
    </FileProvider>
  );
};

export default ScenesWorkflowTester;
