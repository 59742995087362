import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import ScenesError500 from 'scenes/error/500';
import ScenesDataOnly from 'scenes/data_only';
import { Navigate } from 'react-router-dom';
import AddinRoot from 'app/addin/root';
import AddinWordScene from 'scenes/addin/word';

const addinRouter = createBrowserRouter([
  {
    path: '/',
    element: <AddinRoot />,
    children: [
      {
        index: true,
        path: 'word',
        element: <AddinWordScene />,
      },
      {
        path: '/',
        element: <Navigate to="/word" replace />,
      },
      {
        path: '*',
        element: <Navigate to="/word" replace />,
      },
    ],
    errorElement: <ScenesError500 />,
  },
]);

export default addinRouter;
