import React, { useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import WorkflowStatusesSearch from 'components/workflow/statuses/search';
import { useWorkflow } from 'services/workflow/hook';
import { LOCALES } from 'constants';
import WorkflowEmailBuilderForm from 'components/workflow/email_builder/form';

const WorkflowEmailBuilderCard = () => {
  const { t } = useI18n();
  const { selectedWorkflow } = useWorkflow();
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedLocale, setSelectedLocale] = useState(Object.keys(LOCALES)[0]);
  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div>
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {t('components.workflow.email_builder.card.build_email')}
              </h5>
            </div>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">
              <WorkflowStatusesSearch filter={false} />
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {selectedWorkflow && (
          <div className="p-2 bg-200">
            <InputGroup>
              <Form.Select
                size="sm"
                value={selectedEmail ? selectedEmail.id : ''}
                style={{
                  width: '500px',
                }}
                onChange={(ev) => {
                  setSelectedEmail(() =>
                    selectedWorkflow.emails.find(
                      (email) => email.id === ev.target.value,
                    ),
                  );
                }}
              >
                <option>
                  {t('components.workflow.email_builder.card.select_an_email')}
                </option>
                {selectedWorkflow.emails.map((email) => (
                  <option value={email.id} key={email.id}>
                    {email.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                size="sm"
                placeholer={t('components.workflow.email_builder.card.locale')}
                value={selectedLocale}
                onChange={(ev) => {
                  setSelectedLocale(ev.target.value);
                }}
              >
                {Object.keys(LOCALES).map((locale) => (
                  <option value={locale} key={locale}>
                    {t(`global.locales.${locale}`)}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </div>
        )}
        <WorkflowEmailBuilderForm
          email={selectedEmail}
          locale={selectedLocale}
        />
      </Card.Body>
    </Card>
  );
};

export default WorkflowEmailBuilderCard;
