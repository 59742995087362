import React from 'react';
import { InputGroup, Spinner } from 'react-bootstrap';
import { useWorkflow } from 'services/workflow/hook';
import { useI18n } from 'services/i18n/hook';
import EnvironmentSelectField from 'components/environment/select_field';
import Button from 'components/ui/button';
import Select from 'react-select';
import { SELECT_STYLES } from 'constants';

const WorkflowSelector = ({ children }) => {
  const { t } = useI18n();
  const {
    workflows,
    loadingWorkflows,
    setSelectedWorkflow,
    setSelectedEnv,
    selectedEnv,
    selectedWorkflow,
    loadingWorkflow,
    refresh,
  } = useWorkflow();
  const workflowList = workflows.map((workflow) => ({
    value: workflow.id,
    label: `${workflow.product.subject_name} - ${workflow.name}`,
  }));
  const getValue = () =>
    workflowList.find(
      (workflow) =>
        selectedWorkflow && workflow.value === selectedWorkflow.workflow.id,
    );

  return (
    <InputGroup>
      {loadingWorkflows ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Select
            styles={SELECT_STYLES}
            className="workflow-selector"
            options={workflowList}
            onChange={({ value: newWorkflowId }) => {
              setSelectedWorkflow(newWorkflowId);
            }}
            isDisabled={loadingWorkflow}
            value={getValue()}
          />
          <EnvironmentSelectField
            env={selectedEnv}
            setEnv={setSelectedEnv}
            loading={loadingWorkflow}
          />
          {selectedEnv && selectedWorkflow && (
            <Button size="sm" onClick={refresh}>
              {t('components.workflow.selector.refresh')}
            </Button>
          )}
          {loadingWorkflow && (
            <InputGroup.Text>
              <Spinner animation="border" size="sm" />
            </InputGroup.Text>
          )}
        </>
      )}
      {children}
    </InputGroup>
  );
};

export default WorkflowSelector;
