import React from 'react';
import { Alert } from 'react-bootstrap';
import Moment from 'react-moment';
import RequestSearchForm from 'components/request/search/form';
import { useI18n } from 'services/i18n/hook';
import { useRequest } from 'services/request/hook';
import Loader from 'components/ui/loader';
import RequestDataTable from 'components/request/data/table';
import EmptyState from 'components/ui/empty_state';
import EmptyRequestImage from 'images/request_empty.svg';
import Card from 'components/ui/card';
import VariableUsageProvider from 'services/variable_usage/provider';
import { useApplication } from 'services/application/hook';

const RequestDataCard = ({ dataActions }) => {
  const { t } = useI18n();
  const { inIframe } = useApplication();
  const { error, loading, flattenRequestData, loadedAt, request } =
    useRequest();

  return (
    <Card
      id="request-data-card-header"
      title={
        inIframe ? null : (
          <div>
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              {!request
                ? t('components.request.data.card.request_data')
                : request.name}
            </h5>
            <small className="fst-italic">
              {loadedAt && (
                <>
                  {t('components.request.data.card.loaded_at')}
                  <Moment from={loadedAt} format="DD/MM/YYYY HH:mm" />
                </>
              )}
            </small>
          </div>
        )
      }
      actions={<RequestSearchForm />}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {error ? (
            <div className="p-3">
              <Alert variant="danger">{error}</Alert>
            </div>
          ) : (
            <>
              {flattenRequestData ? (
                <VariableUsageProvider>
                  <RequestDataTable dataActions={dataActions} />
                </VariableUsageProvider>
              ) : (
                <EmptyState
                  image={EmptyRequestImage}
                  text={t('components.request.data.card.search_a_request')}
                />
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default RequestDataCard;
