import React, { useState } from 'react';
import SessionSignInForm from 'components/session/sign_in/form';
import { useSession } from 'services/sessions/hook';
import { createSession } from 'requests/sessions';
import SessionSignInOTPForm from 'components/session/sign_in/otp_form';
import { useRecaptcha } from 'services/recaptcha/hook';
import { DEFAULT_ENV } from 'constants';
import Alert from 'react-bootstrap/Alert';
import { useI18n } from 'services/i18n/hook';

const SessionSignInContainer = ({ env = DEFAULT_ENV, onSignInSuccess }) => {
  const { t } = useI18n();
  const [error, setError] = useState(null);
  const { getCaptchaToken } = useRecaptcha();
  const { keepSession } = useSession();
  const [askOTP, setAskOTP] = useState(false);
  const [credentials, setCredentials] = useState(null);
  const onSubmit = (values) => {
    return new Promise((resolve, reject) => {
      setError(null);
      getCaptchaToken('sign_in').then((captchaToken) => {
        createSession(values, captchaToken)
          .then(({ response }) => {
            keepSession(response, values.env || DEFAULT_ENV);
            onSignInSuccess && onSignInSuccess();
          })
          .catch((e) => {
            console.log('got error', e);
            if (e.status === 499) {
              setCredentials(values);
              setAskOTP(true);
              return;
            }
            setError(t('components.session.sign_in.container.error_message'));
            reject(e);
          });
      });
    });
  };
  if (askOTP) {
    return (
      <SessionSignInOTPForm onSubmit={onSubmit} initialValues={credentials} />
    );
  }
  return (
    <>
      <SessionSignInForm
        onSubmit={onSubmit}
        initialValues={{
          env,
        }}
      />
      {error && (
        <Alert className="mt-3 p-2 text-center" variant="danger">
          {error}
        </Alert>
      )}
    </>
  );
};

export default SessionSignInContainer;
