import React from 'react';
import RequestSearchForm from 'components/request/search/form';
import RequestDataTable from 'components/request/data/table';
import WatsonDataPathActions from 'components/watson/data_path_actions';
import { useOffice } from 'services/office/provider';
import { useRequest } from 'services/request/hook';

const AddinWatsonBuilderRequest = () => {
  const { addVariableAttribute } = useOffice();
  const { request } = useRequest();
  return (
    <>
      <RequestSearchForm />
      {request && (
        <RequestDataTable
          dataActions={WatsonDataPathActions}
          onPathSelect={addVariableAttribute}
        />
      )}
    </>
  );
};

export default AddinWatsonBuilderRequest;
