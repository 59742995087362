import isNil from 'lodash/isNil';
import Cookies from 'js-cookie';
import { COOKIE_DOMAIN, PROTOCOL, COOKIES_EXPIRES_IN_DAYS } from 'constants';

const authCookieName = 'authorization_token';
const useSessionStorage =
  window.location.href.includes('localhost') ||
  window !== window.top ||
  window.location.href.includes('ngrok');

export const setAuthorizationToken = (authorizationToken, env) => {
  if (useSessionStorage) {
    return sessionStorage.setItem(
      `${authCookieName}_${env}`,
      authorizationToken,
    );
  }
  Cookies.set(`${authCookieName}_${env}`, authorizationToken, {
    domain: COOKIE_DOMAIN,
    secure: PROTOCOL === 'https',
    sameSite: PROTOCOL === 'https' ? 'None' : 'Lax',
    expires: COOKIES_EXPIRES_IN_DAYS,
  });
};

export const getAuthorizationToken = (env) => {
  if (useSessionStorage) {
    return sessionStorage.getItem(`${authCookieName}_${env}`);
  }
  const token = Cookies.get(`${authCookieName}_${env}`);
  return !isNil(token) && token !== 'null' ? token : undefined;
};

export const deleteAuthorizationToken = (env) => {
  if (useSessionStorage) {
    return sessionStorage.removeItem(`${authCookieName}_${env}`);
  }
  return Cookies.remove(`${authCookieName}_${env}`, { domain: COOKIE_DOMAIN });
};

export const getAuthorizationHeader = (env) => {
  const token = getAuthorizationToken(env);
  if (token) {
    return `Bearer ${getAuthorizationToken(env)}`;
  }
  return undefined;
};
