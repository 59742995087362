import React from 'react';
import { OverlayTrigger, Popover as BsPopover } from 'react-bootstrap';

const Popover = ({ content, title, children, className }) => {
  const popover = (
    <BsPopover className={className}>
      {title && <BsPopover.Head>{title}</BsPopover.Head>}
      <BsPopover.Body className={className}>{content}</BsPopover.Body>
    </BsPopover>
  );
  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <span className="cursor-pointer">{children}</span>
    </OverlayTrigger>
  );
};

export default Popover;
