import React from 'react';
import { Table } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { useWatson } from 'services/watson/hook';

const WatsonWarningTable = () => {
  const { t } = useI18n();
  const { generationWarnings } = useWatson();
  return (
    <Table size="sm" bordered striped responsive className="fs--1">
      <thead>
        <tr>
          <th>{t('components.watson.warning_table.command_code')}</th>
          <th>{t('components.watson.warning_table.message')}</th>
        </tr>
      </thead>
      <tbody>
        {generationWarnings.map((warning, index) => (
          <tr key={index}>
            <td className="text-900">
              <code className="fs--1">{`{{${warning.command_code}}}`}</code>
            </td>
            <td>{warning.message}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default WatsonWarningTable;
