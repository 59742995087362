import {
  Condition,
  ConditionList,
  parseAndChecksStepConditions,
} from '@deecide/utils';
import get from 'lodash/get';
import every from 'lodash/every';

export const createConditionList = (list) => {
  return new ConditionList(
    list.map((condition) => {
      return new Condition({
        ...condition,
      });
    }),
  );
};

export const parseConditions = (src, name) => {
  const conditions = get(src, name, []);
  if (!conditions.length) {
    return {
      stepConditions: [],
      conditions: [],
    };
  }
  return {
    stepConditions: conditions.filter(
      (condition) => condition.kind === 'step_based',
    ),
    conditions: conditions.filter(
      (condition) => condition.kind !== 'step_based',
    ),
  };
};

export const checkStepConditions = (stepConditions, requestData, step) => {
  const stepsMetadata = get(requestData, '__steps_metadata', {});
  return every(stepConditions, (stepCondition) =>
    parseAndChecksStepConditions(stepCondition, stepsMetadata, step),
  );
};
