import get from 'lodash/get';
import { createConditionList } from 'components/workflow/statuses/step_status';

const filterConditions = (conditions = []) => {
  return conditions.filter((condition) => {
    return condition.property === 'status.slug';
  });
};

const checkConditions = (conditions, data) => {
  const conditionList = createConditionList(conditions);
  return conditionList.checkList(data).check;
};

export const extractConditions = (step) => {
  const disableConditions = filterConditions(
    get(step, 'disable_conditions.conditions', []),
  );
  const editConditions = filterConditions(
    get(step, 'edit_conditions.conditions', []),
  );
  const visibilityConditions = filterConditions(
    get(step, 'visibility_conditions.conditions', []),
  );
  return {
    disableConditions,
    editConditions,
    visibilityConditions,
  };
};

export const checkStep = (step, statusSlug) => {
  const { disableConditions, editConditions, visibilityConditions } =
    extractConditions(step);
  const data = {
    status: {
      slug: statusSlug,
    },
  };

  return {
    isDisabled:
      disableConditions.length === 0
        ? false
        : checkConditions(disableConditions, data),
    isEditable:
      editConditions.length === 0
        ? true
        : checkConditions(editConditions, data),
    isVisible:
      visibilityConditions.length === 0
        ? true
        : checkConditions(visibilityConditions, data),
  };
};
