import React, { useState } from 'react';
import RequestNavigationItem from 'components/request/navigation/item';
import CheckIcon from 'components/ui/check_icon';
import {
  isStepDisabled,
  isStepEditable,
  isStepVisible,
  Condition,
  getStepsForProperty,
  checkStepConditions,
} from '@deecide/utils';
import { useRequest } from 'services/request/hook';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useI18n } from 'services/i18n/hook';

const ConditionDetails = ({
  condition,
  request,
  stepsMetadata = {},
  step,
  setStepsToHighlight,
}) => {
  const [highlightProperty, setHighlightProperty] = useState(false);
  const stepBased = condition.kind === 'step_based';
  const stepsForCondition = stepBased
    ? getStepsForProperty(condition.property, stepsMetadata, step)
    : [];
  const cond = new Condition(condition);
  const check = stepBased
    ? checkStepConditions(
        [condition],
        { __steps_metadata: stepsMetadata },
        step,
      )
    : cond.check(request, (path) => {
        return get(request, path, null);
      });
  const getProperty = ({ property }) => {
    if (stepBased) {
    } else {
      const value = get(request, property, 'null');
      return isObject(value) ? '{object}' : isArray(value) ? '[array]' : value;
    }
  };
  const onPropertyClick = (property) => {
    if (!stepBased) {
      console.log(property, get(request, property, null));
      return;
    }
    console.log('target steps', stepsForCondition);
    setStepsToHighlight(highlightProperty ? [] : stepsForCondition);
    setHighlightProperty((prev) => !prev);
  };

  return (
    <div className="d-flex align-items-center">
      <CheckIcon check={check} />
      <Badge size="sm" bg="secondary" className="ms-1 me-2">
        {condition.operator.toUpperCase()}
      </Badge>
      <Badge
        size="sm"
        bg={highlightProperty ? 'primary' : 'secondary'}
        className="me-2 cursor-pointer"
        onClick={() => onPropertyClick(condition.property)}
      >
        {condition.property}
      </Badge>
      {condition.assertion || condition.expectation}
      <Badge size="sm" bg="secondary" className="ms-2">
        {condition.value}
      </Badge>
      <code className="ms-2 fs--1">{getProperty(condition)}</code>
    </div>
  );
};

const RequestNavigationRow = ({
  number,
  step,
  parentStep,
  stepsMetadata,
  setStepsToHighlight,
  highlighted,
}) => {
  const { t } = useI18n();
  const { request } = useRequest();
  const requestData = {
    ...request,
    __steps_metadata: stepsMetadata,
  };
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    console.log('Toggle step', step.id);
    setExpanded((prev) => !prev);
  };
  const getConditions = (type) => {
    return get(step, `${type}.conditions`, []);
  };

  const visibilityConditions = getConditions('visibility_conditions');
  const disableConditions = getConditions('disable_conditions');
  const editConditions = getConditions('edit_conditions');
  const errorCount = get(
    stepsMetadata,
    `${step.parent_id ? `${step.parent_id}.steps` : ''}.${
      step.id
    }.errors_count`,
    0,
  );
  const isVisible = isStepVisible(step, parentStep, requestData);
  return (
    <tbody>
      <tr
        className={classNames('cursor-pointer', {
          'bg-light': expanded,
          'bg-soft-primary': highlighted,
          'fw-bold': !parentStep,
          'opacity-25': !isVisible,
        })}
        onClick={toggleExpand}
      >
        <td
          className={classNames({
            'ps-4': parentStep,
          })}
        >
          <RequestNavigationItem step={step} number={number} />
        </td>
        <td className="text-center">
          {errorCount > 0 ? (
            <span className={classNames({ 'text-danger': errorCount > 0 })}>
              {errorCount}
            </span>
          ) : (
            '-'
          )}
        </td>
        <td className="text-center">
          <CheckIcon check={isVisible} />
        </td>
        <td className="text-center">
          <CheckIcon check={isStepEditable(step, parentStep, requestData)} />
        </td>
        <td className="text-center">
          {isStepDisabled(step, parentStep, requestData) && (
            <FontAwesomeIcon
              icon={['fa', 'fa-lock']}
              className="text-secondary"
            />
          )}
        </td>
      </tr>
      {expanded && (
        <tr className="bg-light fs--1">
          <td colSpan={5} className="ps-4">
            {visibilityConditions.length > 0 && (
              <>
                <h6>
                  <CheckIcon check={isVisible} />{' '}
                  {t('components.request.navigation.row.visible')}
                </h6>
                <ul>
                  {visibilityConditions.map((condition, index) => {
                    return (
                      <li key={index} className="d-flex">
                        <ConditionDetails
                          condition={condition}
                          request={requestData}
                          stepsMetadata={stepsMetadata}
                          step={step}
                          setStepsToHighlight={setStepsToHighlight}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
            {editConditions.length > 0 && (
              <>
                <h6>
                  <CheckIcon
                    check={isStepEditable(step, parentStep, requestData)}
                  />{' '}
                  {t('components.request.navigation.row.editable')}
                </h6>
                <ul>
                  {editConditions.map((condition, index) => {
                    return (
                      <li key={index}>
                        <ConditionDetails
                          condition={condition}
                          request={requestData}
                          stepsMetadata={stepsMetadata}
                          step={step}
                          setStepsToHighlight={setStepsToHighlight}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
            {disableConditions.length > 0 && (
              <>
                <h6>
                  <FontAwesomeIcon
                    icon={[
                      'fa',
                      isStepDisabled(step, parentStep, requestData)
                        ? 'fa-lock'
                        : 'fa-lock-open',
                    ]}
                    className="text-secondary"
                  />{' '}
                  {t('components.request.navigation.row.disabled')}
                </h6>
                <ul>
                  {disableConditions.map((condition, index) => {
                    return (
                      <li key={index}>
                        <ConditionDetails
                          condition={condition}
                          request={requestData}
                          stepsMetadata={stepsMetadata}
                          step={step}
                          setStepsToHighlight={setStepsToHighlight}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default RequestNavigationRow;
