import React from 'react';
import { useRequest } from 'services/request/hook';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import isBoolean from 'lodash/isBoolean';
import Highlighter from 'react-highlight-words';
import { useApplication } from 'services/application/hook';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const RequestDataRow = ({ item, children, search, dataSize }) => {
  const { inIframe } = useApplication();
  const { request } = useRequest();
  if (!item) return null;
  const itemData = get(request, item.path);
  const content = isArray(itemData)
    ? itemData.join(' || ')
    : isBoolean(itemData)
    ? itemData
      ? 'TRUE'
      : 'FALSE'
    : isNull(itemData)
    ? 'NULL'
    : isUndefined(itemData)
    ? 'UNDEFINED'
    : itemData;

  if (isObject(content)) {
    return null;
  }

  return (
    <tr
      key={item.index}
      className="data-path-row actions-container"
      style={{ position: 'relative' }}
    >
      <td className={!inIframe ? 'text-break' : 'text-break action-cell'}>
        {inIframe ? (
          <>
            <OverlayTrigger
              placement="top-start"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Body>{content}</Popover.Body>
                </Popover>
              }
            >
              <div>
                <Highlighter
                  textToHighlight={item.path}
                  searchWords={[search]}
                  autoEscape
                  highlightClassName="bg-soft-warning"
                />
              </div>
            </OverlayTrigger>
            {children}
          </>
        ) : (
          <Highlighter
            textToHighlight={item.path}
            searchWords={[search]}
            autoEscape
            highlightClassName="bg-soft-warning"
          />
        )}
      </td>
      {!inIframe && (
        <td className="text-break action-cell">
          {content}
          {children}
        </td>
      )}
    </tr>
  );
};

export default RequestDataRow;
