import React, { useState } from 'react';
import Button from 'components/ui/button';
import { Modal } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import VariableUsageTable from './table';
import { useApplication } from 'services/application/hook';

const VariableUsagePathActionButton = ({ path }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useI18n();
  const { inIframe } = useApplication();
  if (inIframe) {
    return null;
  }
  return (
    <>
      <Modal
        onHide={() => setModalOpen((prev) => !prev)}
        show={modalOpen}
        size="xl"
      >
        <Modal.Header className="d-flex justify-content-start align-center">
          {t('components.workflow.variable_usage.path_action_button.title')}
          <code className="fs--1 ms-1">{path}</code>
        </Modal.Header>
        <Modal.Body>
          <VariableUsageTable path={path} />
        </Modal.Body>
      </Modal>
      <Button
        size="sm"
        variant="outline-secondary"
        icon={['fas', 'fa-magnifying-glass']}
        onClick={() => setModalOpen(!modalOpen)}
      />
    </>
  );
};

export default VariableUsagePathActionButton;
