import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import flattenDeep from 'lodash/flattenDeep';
import { flatData } from 'services/flat_data';

const getBlocksTranslation = (blocks) => {
  const flattenedTranslations = {};

  blocks.forEach((block) => {
    const blockTranslations = get(block, 'form_schema.translations', null);
    if (blockTranslations) {
      const blockTran = {};
      Object.keys(blockTranslations).forEach((locale) => {
        const flattened = flatData(blockTranslations[locale]);
        set(blockTran, locale, flattened);
      });
      set(flattenedTranslations, block.slug, blockTran);
    }
  });
  return flattenedTranslations;
};

const parseForBlockTranslations = (workflow) => {
  let translations = {};
  workflow.steps.forEach((businessStep) => {
    businessStep.steps.forEach((subStep) => {
      if (subStep.multis.length > 0) {
        translations = {
          ...translations,
          ...getBlocksTranslation(
            flattenDeep(subStep.multis.map((multi) => multi.blocks)),
          ),
        };
      }
      translations = {
        ...translations,
        ...getBlocksTranslation(subStep.blocks),
      };
    });
  });
  return translations;
};

export const getAllTranslations = (workflow) => {
  const workflowTranslations = get(workflow, 'translations', {});
  const translations = {
    ...workflowTranslations,
  };
  const blockTranslations = parseForBlockTranslations(workflow);
  Object.keys(blockTranslations).forEach((translationKey) => {
    Object.keys(blockTranslations[translationKey]).forEach((locale) => {
      set(
        translations,
        `${locale}.${translationKey}`,
        blockTranslations[translationKey][locale],
      );
    });
  });
  return translations;
};

const reverseLocale = (translations) => {
  // from { fr: {translation_key: ''}, nl: {translation_key: ''} }
  // to { translation_key: { fr: '', nl: '' } }
  const locales = Object.keys(translations);
  const reversedTranslations = {};
  locales.forEach((locale) => {
    const flattenedTranslationsForLocale = flatData(translations[locale]);
    Object.keys(flattenedTranslationsForLocale).forEach((translationKey) => {
      set(
        reversedTranslations,
        `${translationKey}.${locale}`,
        flattenedTranslationsForLocale[translationKey],
      );
    });
  });
  return { ...reversedTranslations };
};

const flatTranslationKeys = (translations, locales) => {
  const flattened = {};
  const flat = flatData(translations);
  Object.keys(flat).forEach((translationKey) => {
    const localeIndexInKey = locales.findIndex((locale) =>
      translationKey.endsWith(locale),
    );
    if (localeIndexInKey > -1) {
      const oldKey = translationKey;
      const newKey = oldKey.replace(
        new RegExp(`\.${locales[localeIndexInKey]}$`),
        '',
      );
      const translationForNewKey = {};
      locales.map((locale) => {
        translationForNewKey[locale] = get(flat, `${newKey}.${locale}`, '');
      });
      flattened[newKey] = translationForNewKey;
    }
  });
  return { ...flattened };
};

const flatTranslations = (workflow) => {
  const blockTranslations = parseForBlockTranslations(workflow);
  const workflowTranslations = get(workflow, 'translations', {});
  const flattenedTranslations = {};

  // convert all translations found in workflow blocks
  Object.keys(blockTranslations).forEach((blockSlug) => {
    Object.keys(blockTranslations[blockSlug]).forEach((locale) => {
      Object.keys(blockTranslations[blockSlug][locale]).forEach(
        (translationKey) => {
          set(
            flattenedTranslations,
            `${translationKey}.${locale}`,
            blockTranslations[blockSlug][locale][translationKey],
          );
        },
      );
    });
  });

  // convert all translations defined at the workflow level
  const reversedFlattenedWorkflowTranslations = flatData(
    reverseLocale(workflowTranslations),
  );
  Object.keys(reversedFlattenedWorkflowTranslations).forEach(
    (translationKey) => {
      const workflowTranslation =
        reversedFlattenedWorkflowTranslations[translationKey];
      if (!isEmpty(workflowTranslation)) {
        set(flattenedTranslations, translationKey, workflowTranslation);
      }
    },
  );

  return { ...flattenedTranslations };
};

export const parseForTranslations = (workflow, availableLocales = []) => {
  return flatTranslationKeys(flatTranslations(workflow), availableLocales);
};
