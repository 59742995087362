import { LANSKY_ENVS } from 'constants';
import Api from 'services/api';
import axios from 'axios';

export const getWorkflows = (env) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .get(`/explorer/workflows`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkflow = (id, env) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .get(`/explorer/workflows/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAvailableData = (id, env) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${LANSKY_ENVS[env].path}/workflows/list_variables/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
