import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { useWorkflow } from 'services/workflow/hook';
import Loader from 'components/ui/loader';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import compact from 'lodash/compact';
import flattenDeep from 'lodash/flattenDeep';
import WorkflowStatusesStepStatus from 'components/workflow/statuses/step_status';
import { Badge } from 'react-bootstrap';
import './table.css';
import Popover from 'components/ui/popover';
import { extractConditions } from 'services/step';
import { useI18n } from 'services/i18n/hook';
import { useApplication } from 'services/application/hook';

const orderSteps = (workflow) => {
  return orderBy(workflow.steps, 'position').map((businessStep) => {
    return {
      ...businessStep,
      steps: orderBy(businessStep.steps, 'position').map((subStep) => {
        return {
          ...subStep,
          blocks: orderBy(subStep.blocks, 'position'),
        };
      }),
    };
  });
};

const extractChangeStatuses = (steps) => {
  const changeStatusOperations = [];
  steps.forEach((businessStep) => {
    businessStep.steps.forEach((subStep) =>
      subStep.blocks.forEach((block) => {
        const operations = get(block, 'operations', []);
        changeStatusOperations.push(
          operations.filter((operation) => operation.kind === 'change_status'),
        );
      }),
    );
  });
  return compact(flattenDeep(changeStatusOperations));
};

export const getChangeStatusOperations = ({
  workflow,
  statuses,
  orderFor = 'seller',
}) => {
  const orderedSteps = orderSteps(workflow);
  const operations = extractChangeStatuses(orderedSteps);
  const orderedStatuses = operations.map((operation) => {
    return statuses.find((status) => status.id === operation.status_id);
  });
  let filteredStatuses = [];
  orderedStatuses.forEach((status) => {
    const index = filteredStatuses.findIndex(
      (filteredStatus) => filteredStatus.id === status.id,
    );
    if (index === -1) {
      filteredStatuses.push(status);
    }
  });
  const defaultStatus = statuses.find((status) => {
    if (orderFor === 'seller') {
      return status.seller_default;
    }
    return status.provider_default;
  });
  filteredStatuses = filteredStatuses.filter(
    (status) => status.id !== defaultStatus.id,
  );
  filteredStatuses.unshift(defaultStatus);
  return filteredStatuses;
};

const WorkflowStatusesTable = ({ showProvider, showSeller }) => {
  const { t } = useI18n();
  const { getAttribute } = useApplication();
  const { selectedWorkflow, loadingWorkflow } = useWorkflow();
  if (loadingWorkflow) {
    return <Loader />;
  }
  if (!selectedWorkflow) {
    return null;
  }
  const statusesSeller = getChangeStatusOperations({
    workflow: selectedWorkflow.workflow,
    statuses: selectedWorkflow.statuses,
    orderFor: 'seller',
  });
  const statusesProvider = getChangeStatusOperations({
    workflow: selectedWorkflow.workflow,
    statuses: selectedWorkflow.statuses,
    orderFor: 'provider',
  });
  const statusColsLength =
    (showSeller && statusesSeller.length) +
    (showProvider && statusesProvider.length);
  const orderedSteps = orderSteps(selectedWorkflow.workflow);
  return (
    <Table
      size="sm"
      bordered
      hover
      style={{ fontSize: '12px' }}
      className="status-table"
      responsive
    >
      <thead className="bg-300">
        <tr>
          <th>{t('components.workflow.statuses.table.business_steps')}</th>
          <th>{t('components.workflow.statuses.table.sub_steps')}</th>
          <th colSpan={statusColsLength} />
        </tr>
        <tr>
          <th />
          <th />
          {showSeller && (
            <th
              colSpan={statusesSeller.length}
              className="border-left-separator"
            >
              {t('components.workflow.statuses.table.seller')}
            </th>
          )}
          {showProvider && (
            <th
              colSpan={statusesProvider.length}
              className="border-left-separator"
            >
              {t('components.workflow.statuses.table.provider')}
            </th>
          )}
        </tr>
        <tr>
          <th />
          <th />
          {showSeller &&
            statusesSeller.map((statusSeller, index) => (
              <th
                key={statusSeller.id}
                className={index === 0 ? 'border-left-separator' : ''}
              >
                <p className="mb-0">
                  {getAttribute(statusSeller, 'seller_name')}
                </p>
                <Badge
                  bg={statusSeller.color}
                  className={statusSeller.color === 'light' ? 'text-900' : ''}
                >
                  {statusSeller.slug}
                </Badge>
              </th>
            ))}
          {showProvider &&
            statusesProvider.map((statusProvider, index) => (
              <th
                key={statusProvider.id}
                className={index === 0 ? 'border-left-separator' : ''}
              >
                <p className="mb-0">
                  {getAttribute(statusProvider, 'provider_name')}
                </p>
                <Badge
                  bg={statusProvider.color}
                  className={statusProvider.color === 'light' ? 'text-900' : ''}
                >
                  {statusProvider.slug}
                </Badge>
              </th>
            ))}
        </tr>
      </thead>
      {orderedSteps.map((businessStep) => (
        <tbody key={businessStep.id}>
          {businessStep.steps.map((substep, substepIndex) => {
            const businessStepBO = businessStep.backoffice_only;
            return (
              <tr key={substepIndex}>
                <td>
                  {substepIndex === 0 ? (
                    <Popover
                      className="fs--xs"
                      content={
                        <>
                          <pre>
                            {JSON.stringify(
                              extractConditions(businessStep),
                              null,
                              2,
                            )}
                          </pre>
                        </>
                      }
                    >
                      <>
                        {businessStepBO && (
                          <Badge bg="warning" className="bo-badge badge-xs">
                            BO
                          </Badge>
                        )}
                        {getAttribute(businessStep, 'name')}
                      </>
                    </Popover>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  <Popover
                    className="fs--xs"
                    content={
                      <>
                        <pre>
                          {JSON.stringify(extractConditions(substep), null, 2)}
                        </pre>
                      </>
                    }
                  >
                    <>
                      {businessStepBO && (
                        <Badge bg="warning" className="bo-badge badge-xs">
                          BO
                        </Badge>
                      )}
                      {getAttribute(substep, 'name')}
                    </>
                  </Popover>
                </td>
                {showSeller &&
                  statusesSeller.map((statusSeller, index) => {
                    return (
                      <td
                        key={statusSeller.id}
                        className={`text-center align-middle ${
                          index === 0 ? 'border-left-separator' : ''
                        }`}
                      >
                        <WorkflowStatusesStepStatus
                          parentStep={businessStep}
                          step={substep}
                          status={statusSeller}
                          forRole="seller"
                        />
                      </td>
                    );
                  })}
                {showProvider &&
                  statusesProvider.map((statusProvider, index) => (
                    <td
                      key={statusProvider.id}
                      className={`text-center align-middle ${
                        index === 0 ? 'border-left-separator' : ''
                      }`}
                    >
                      <WorkflowStatusesStepStatus
                        parentStep={businessStep}
                        step={substep}
                        status={statusProvider}
                        forRole="provider"
                      />
                    </td>
                  ))}
              </tr>
            );
          })}
        </tbody>
      ))}
    </Table>
  );
};

export default WorkflowStatusesTable;
