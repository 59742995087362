import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RequestDataCard from 'components/request/data/card';
import RequestProvider from 'services/request/provider';
import LanskyCard from 'components/lansky/card';
import FileProvider from 'services/file/provider';
import LanskyProvider from 'services/lansky/provider';
import LanskyDataPathActions from 'components/lansky/data_path_actions';

const ScenesLanskyBuilder = () => {
  return (
    <FileProvider>
      <RequestProvider>
        <LanskyProvider>
          <Row className="g-3 mb-3">
            <Col md={7}>
              <RequestDataCard dataActions={LanskyDataPathActions} />
            </Col>
            <Col md={5}>
              <LanskyCard />
            </Col>
          </Row>
        </LanskyProvider>
      </RequestProvider>
    </FileProvider>
  );
};

export default ScenesLanskyBuilder;
