import axios from 'axios';
import { LANSKY_ENVS } from 'constants';
import has from 'lodash/has';

export const getComputer = (computer) => {
  if (!has(computer, 'computer.active_version_creator_email')) {
    return {
      ...computer.computer,
      blob: {
        file_url: computer.computer_url,
        filename: computer.name,
      },
    };
  }
  return {
    ...computer.computer,
    active_version: {
      version_number: 1,
      file: {
        file_url: computer.computer_url,
        filename: computer.computer.name,
      },
    },
  };
};

export const parseLansky = (
  computer,
  env = 'development',
  currentSessions,
  options,
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${LANSKY_ENVS[env].path}/list_inputs_outputs`,
        {
          computer,
          ...options,
        },
        {
          headers: {
            Authorization: `Bearer ${currentSessions[env].authorizationToken}`,
            Environment: env,
          },
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
};

export const listLanskyInputs = (
  computer,
  env = 'development',
  currentSessions,
  options,
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${LANSKY_ENVS[env].path}/list_inputs`,
        {
          ...computer,
          ...options,
        },
        {
          headers: {
            Authorization: `Bearer ${currentSessions[env].authorizationToken}`,
            Environment: env,
          },
        },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => reject(e));
  });
};
