/* global Word */

import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';

const ATTR = 'ig_var';

const OfficeContext = createContext({ inAddin: false });

export const useOffice = () => useContext(OfficeContext);

const extractPlaceholders = (content) => {
  const regex = /{{[^}]+}}/g;
  const matches = content.match(regex);
  return matches || [];
};

const OfficeProvider = ({ children }) => {
  const [isOfficeInitialized, setIsOfficeInitialized] = useState(false);
  const [officeInstance, setOfficeInstance] = useState(null);
  const [wordInstance, setWordInstance] = useState(null);

  const addVariableAttribute = async (value) => {
    try {
      await Word.run(async (context) => {
        const selection = context.document.getSelection();
        selection.load('text');
        await context.sync();

        // Add custom attribute to the XML
        const xmlAttribute = `${ATTR}="${value}"`;
        const range = selection.getRange();

        // Create a content control and set its tag to store the custom attribute
        const contentControl = range.insertContentControl();
        contentControl.tag = xmlAttribute;

        // Highlight the text visually
        range.font.highlightColor = '#FFFF00'; // Yellow highlight

        await context.sync();
        console.log('Custom attribute added and text highlighted');
      });
    } catch (error) {
      console.error('Error adding custom attribute and highlighting:', error);
    }
  };

  const listVariableAttributes = async () => {
    try {
      await Word.run(async (context) => {
        const contentControls = context.document.contentControls;
        contentControls.load('items');
        await context.sync();

        const customAttrControls = contentControls.items.filter(
          (control) => control.tag && control.tag.startsWith(`${ATTR}=`),
        );

        for (let control of customAttrControls) {
          control.load('text, tag');
        }
        await context.sync();

        const results = customAttrControls.map((control) => ({
          text: control.text,
          attribute: control.tag,
        }));

        console.log('Custom attributes found:', results);
        return results;
      });
    } catch (error) {
      console.error('Error listing custom attributes:', error);
    }
  };

  const loadOfficeScript = (callback) => {
    const script = document.createElement('script');
    script.src = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';
    script.onload = () => {
      // Script has loaded, now we can safely call Office.onReady
      if (callback) callback();
    };
    script.onerror = () => {
      console.error('Failed to load Office.js script.');
    };
    document.head.appendChild(script);
  };

  const onFocusVariableAttribute = async (callback) => {
    try {
      await Word.run(async (context) => {
        // Get the current selection
        const selection = context.document.getSelection();
        selection.load('contentControls');
        await context.sync();

        // Check if the selection is within a content control
        if (selection.contentControls.items.length > 0) {
          const contentControl = selection.contentControls.items[0];
          contentControl.load('tag');
          await context.sync();

          // Check if the content control has our custom attribute
          if (contentControl.tag && contentControl.tag.startsWith(`${ATTR}=`)) {
            // Extract the value from the tag
            const value = contentControl.tag.split('=')[1].replace(/"/g, '');
            callback(value);
          }
        }
      });
    } catch (error) {
      console.error('Error in onFocusCustomAttribute:', error);
    }
  };

  const getDocumentAsBase64 = async () => {
    console.log('getDocumentAsBase64');
    try {
      return await Word.run(async (context) => {
        const body = context.document.body;
        const bodyOoxml = body.getOoxml();
        console.log('here');
        await context.sync();

        // Convert the OOXML to base64
        const base64 = btoa(unescape(encodeURIComponent(bodyOoxml.value)));
        return base64;
      });
    } catch (error) {
      console.error('Error getting document as base64:', error);
      throw error;
    }
  };

  const getDocumentAsFile = async () => {
    try {
      return new Promise((resolve, reject) => {
        Office.context.document.getFileAsync(
          Office.FileType.Compressed,
          { sliceSize: 65536 },
          (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
              const file = result.value;
              let sliceCount = file.sliceCount;
              let slices = [];

              const getSlice = (sliceIndex) => {
                file.getSliceAsync(sliceIndex, (sliceResult) => {
                  if (
                    sliceResult.status === Office.AsyncResultStatus.Succeeded
                  ) {
                    slices.push(sliceResult.value.data);
                    if (sliceIndex < sliceCount - 1) {
                      getSlice(sliceIndex + 1);
                    } else {
                      file.closeAsync();
                      const blob = new Blob(slices, {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      });
                      const fileName = 'document.docx';
                      const fileObject = new File([blob], fileName, {
                        type: blob.type,
                      });
                      resolve(fileObject);
                    }
                  } else {
                    file.closeAsync();
                    reject(sliceResult.error);
                  }
                });
              };

              getSlice(0);
            } else {
              reject(result.error);
            }
          },
        );
      });
    } catch (error) {
      console.error('Error getting document as file:', error);
      throw error;
    }
  };

  useEffect(() => {
    loadOfficeScript(() => {
      Office.onReady((info) => {
        setIsOfficeInitialized(true);
        setOfficeInstance(Office);
        setWordInstance(Word);
      });
    });
  }, []);
  if (!isOfficeInitialized) {
    return <div>Loading Office...</div>;
  }

  return (
    <OfficeContext.Provider
      value={{
        Office: officeInstance,
        inAddin: true,
        Word: wordInstance,
        addVariableAttribute,
        listVariableAttributes,
        onFocusVariableAttribute, // Add this new function to the context
        getDocumentAsBase64, // Add this new function to the context
        getDocumentAsFile, // Add this new function to the context
      }}
    >
      {children}
    </OfficeContext.Provider>
  );
};

export default OfficeProvider;
