import React from 'react';
import { Field as FinalFormField } from 'react-final-form';
import isArray from 'lodash/isArray';

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined,
    );

const Field = ({ validate, ...restProps }) => {
  return (
    <FinalFormField
      validate={isArray(validate) ? composeValidators(...validate) : validate}
      {...restProps}
    />
  );
};

export default Field;
