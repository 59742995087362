import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import ApplicationContext from 'services/application/context';
import { useSession } from 'services/sessions/hook';
import { isInIframe } from 'services/iframe';
import { Helmet } from 'react-helmet-async';

const ApplicationProvider = ({ children, addinContext }) => {
  const { getFirstSession } = useSession();
  const session = getFirstSession();
  const locale = get(session, 'user.locale', 'nl');
  const inIframe = isInIframe();
  const getAttribute = (object, path) => {
    return get(object, `${path}_translations.${locale}`);
  };

  const [globalRequest, setGolbalRequest] = useState(null);
  const [globalRequestEnv, setGolbalRequestEnv] = useState(null);

  return (
    <ApplicationContext.Provider
      value={{
        inIframe: addinContext ? true : inIframe,
        getAttribute,
        globalRequest,
        globalRequestEnv,
        addinContext,
        setGlobalRequest: (request, env) => {
          setGolbalRequest(request);
          setGolbalRequestEnv(env);
        },
      }}
    >
      <Helmet>
        <body className={inIframe ? 'in-iframe' : ''} />
      </Helmet>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationProvider;
