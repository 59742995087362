import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'components/ui/button';
import { useI18n } from 'services/i18n/hook';
import { useFile } from 'services/file/hook';
import { useWorkflow } from 'services/workflow/hook';
import {
  getOutputData,
  getTestInputs,
  generateTestResults,
} from 'services/xlsx';
import PaulApi from 'services/paul_api';

const WorkflowTesterSelector = () => {
  const { t } = useI18n();
  const { selectFile } = useFile();
  const [file, setFile] = useState(null);
  const [dataForOutput, setDataForOutput] = useState(null);
  const { selectedWorkflow, loading, selectedEnv } = useWorkflow();
  const [testCases, setTestCases] = useState([]);
  const [allRun, setAllRun] = useState(false);
  const [allRunning, setAllRunning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [runDuration, setRunDuration] = useState(0);

  const getFile = async () => {
    const selectedFile = await selectFile();
    loadTests(selectedFile);
  };

  const runTest = (testIndex) => {
    return new Promise((resolve) => {
      const testCase = testCases[testIndex];
      const newTestCases = [...testCases];
      newTestCases[testIndex].status.running = true;
      console.log('running test', testIndex, testCase.data.input);
      setTestCases(newTestCases);
      PaulApi.use(selectedEnv)
        .post(`/workflows/${selectedWorkflow.workflow.id}/compute`, {
          data: testCase.data.input,
        })
        .then((response) => {
          console.log('test response', response.data);
          const newTestCases = [...testCases];
          newTestCases[testIndex].status.done = true;
          newTestCases[testIndex].status.running = false;
          newTestCases[testIndex].data.output = response.data;
          setTestCases(newTestCases);
          resolve(newTestCases);
        })
        .catch((error) => {
          console.error(error);
          const newTestCases = [...testCases];
          newTestCases[testIndex].status.done = true;
          newTestCases[testIndex].status.running = false;
          newTestCases[testIndex].status.failed = true;
          newTestCases[testIndex].data.output = error.response.data;
          setTestCases(newTestCases);
        });
    });
  };

  const loadTests = async (fileToLoad) => {
    const testCases = await getTestInputs(fileToLoad);
    const outputData = await getOutputData(fileToLoad);
    setDataForOutput(outputData);
    const completeCases = testCases.map((testCase) => {
      return {
        data: {
          input: testCase,
          output: {},
        },
        status: {
          done: false,
          running: false,
          failed: false,
        },
      };
    });
    setTestCases(completeCases);
  };

  const runAll = () => {
    setAllRunning(true);
    let completed = 0;
    setProgress(0);
    const start = Date.now();
    const newTestCases = [...testCases];

    // Chain promises
    newTestCases
      .reduce((promiseChain, testCase, index) => {
        return promiseChain
          .then(() => runTest(index))
          .then(() => {
            completed++;
            setProgress((completed / newTestCases.length) * 100);
          });
      }, Promise.resolve())
      .then(() => {
        const end = Date.now();
        setRunDuration((end - start) / 1000);
        setAllRun(true);
        setAllRunning(false);
      });
  };

  return (
    <div className="p-2">
      <label>
        {t('components.workflow.tester.selector.form.select_test_file')}
      </label>
      <InputGroup className="mb-2">
        <Button
          variant="secondary"
          size="sm"
          onClick={getFile}
          icon={['fa', 'file-word']}
        >
          {t('components.workflow.tester.selector.form.select_file')}
        </Button>
        <Form.Control
          size="sm"
          placeholder={t(
            'components.workflow.tester.selector.form.no_file_selected',
          )}
          value={file ? file.name : ''}
          disabled
          onChange={() => {}}
        />
      </InputGroup>
      {testCases.length > 0 && (
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th style={{ width: '2%', textAlign: 'center' }}>#</th>
              <th style={{ width: '78%' }}>Case</th>
              <th style={{ width: '10%', textAlign: 'center' }}>Status</th>
              <th style={{ width: '10%', textAlign: 'center' }}>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={runAll}
                  disabled={allRunning}
                >
                  {!allRunning
                    ? t('components.workflow.tester.selector.form.run_all')
                    : t('components.workflow.tester.selector.form.progress', {
                        progress: Math.round(progress),
                      })}
                </Button>
              </th>
            </tr>
          </thead>
          <tbody>
            {testCases.map((testCase, index) => (
              <tr key={index}>
                <th style={{ width: '2%', textAlign: 'center' }} scope="row">
                  {index + 1}
                </th>
                <th style={{ width: '78%' }} scope="row"></th>
                <td style={{ width: '10%', textAlign: 'center' }}>
                  {testCase.status.done
                    ? 'Done'
                    : testCase.status.running
                    ? 'Running'
                    : 'Pending'}
                </td>
                <td style={{ width: '10%', textAlign: 'center' }}>
                  <Button
                    variant="secondary"
                    size="sm"
                    disabled={allRunning || testCase.status.running}
                    onClick={() => {
                      runTest(index);
                    }}
                  >
                    {t('components.workflow.tester.selector.form.run_test')}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
          {allRun && (
            <tfoot>
              <tr>
                <td colSpan="4" style={{ textAlign: 'right' }}>
                  <small className="me-2">
                    {t(
                      'components.workflow.tester.selector.form.run_duration',
                      { duration: runDuration },
                    )}
                  </small>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      generateTestResults(testCases, dataForOutput);
                    }}
                  >
                    {t(
                      'components.workflow.tester.selector.form.download_results',
                    )}
                  </Button>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      )}
    </div>
  );
};

export default WorkflowTesterSelector;
