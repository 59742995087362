import React from 'react';
import Button from 'components/ui/button';
import { Form, InputGroup } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { useApiDebug } from 'services/api_debug/hook';

const ApiDebugSearch = () => {
  const { t } = useI18n();
  const { apiPath, setApiPath, loading, callApi } = useApiDebug();
  return (
    <InputGroup className="mb-2">
      <Form.Control
        size="sm"
        placeholder={t('components.api_debug.search.enter_an_api_url')}
        value={apiPath}
        disabled={loading}
        onChange={(ev) => setApiPath(ev.target.value)}
      />
      <Button
        variant="secondary"
        size="sm"
        disabled={loading || apiPath.length < 3}
        onClick={() => callApi()}
        icon={['fa', 'paper-plane']}
      >
        {t('components.api_debug.search.call_api')}
      </Button>
    </InputGroup>
  );
};

export default ApiDebugSearch;
