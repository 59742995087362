import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import WorkflowStatusesSearch from 'components/workflow/statuses/search';
import WorkflowStatusesTable from 'components/workflow/statuses/table';
import { useI18n } from 'services/i18n/hook';
import { useWorkflow } from 'services/workflow/hook';
import EmptyWorkflowStatusImage from 'images/workflow_status_empty.svg';
import EmptyState from 'components/ui/empty_state';
import Loader from 'components/ui/loader';

const WorkflowStatuses = () => {
  const { t } = useI18n();
  const { selectedWorkflow, loading } = useWorkflow();
  const [showSeller, setShowSeller] = useState(true);
  const [showProvider, setShowProvider] = useState(true);
  return (
    <>
      <Card>
        <Card.Header className="bg-light">
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  {selectedWorkflow
                    ? selectedWorkflow.workflow.subject_name
                    : t('components.workflow.statuses.index.select_a_workflow')}
                </h5>
              </div>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
              <div id="orders-actions">
                <WorkflowStatusesSearch
                  filter
                  showSeller={showSeller}
                  showProvider={showProvider}
                  setShowProvider={setShowProvider}
                  setShowSeller={setShowSeller}
                />
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          {loading ? (
            <Loader />
          ) : (
            <>
              {selectedWorkflow ? (
                <WorkflowStatusesTable
                  showSeller={showSeller}
                  showProvider={showProvider}
                />
              ) : (
                <EmptyState
                  image={EmptyWorkflowStatusImage}
                  text={t(
                    'components.workflow.statuses.index.select_a_workflow',
                  )}
                />
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default WorkflowStatuses;
