import React from 'react';

const ScenesSandbox = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html:
          '<meta http-equiv="Content-Type" content="text/html; charset=utf-8"><div dir="ltr">Hello worl</div>',
      }}
    ></div>
  );
};

export default ScenesSandbox;
