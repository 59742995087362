import React, { useEffect, useState } from 'react';
import ErrorBoundary from 'services/error_boundary';
import { Liquid } from 'liquidjs';
import { decode } from 'html-entities';
import { Alert } from 'react-bootstrap';

const RenderLiquid = ({ template, data, ...rest }) => {
  const [markup, setMarkup] = useState(null);

  const engine = new Liquid();

  useEffect(() => {
    setMarkup(null);
    const tpl = engine.parse(decode(template));
    engine.render(tpl, data).then((markup) => {
      setMarkup(markup);
    });
  }, [template]);
  if (!markup) {
    return null;
  }
  return <div {...rest} dangerouslySetInnerHTML={{ __html: markup }} />;
};

export default (props) => (
  <ErrorBoundary
    fallback={(props) => {
      return <Alert variant="danger">{props.error}</Alert>;
    }}
  >
    <RenderLiquid {...props} />
  </ErrorBoundary>
);
