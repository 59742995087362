import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import SoftBadge from 'components/ui/theme/components/common/SoftBadge';
import { Link } from 'react-router-dom';
import { useSession } from 'services/sessions/hook';
import findKey from 'lodash/findKey';
import isEmpty from 'lodash/isEmpty';
import { SESSION_ENVS } from 'constants';
import SessionSignInButton from 'components/session/sign_in/button';
import SessionSignOutButton from 'components/session/sign_out/button';
import { useClickOutside } from 'services/use_click_outside';
import { DEVELOPMENT } from 'constants';

const NavigationUserMenu = () => {
  const { currentSessions } = useSession();
  const firstEnvAvailable = findKey(currentSessions, (key) => {
    return !isEmpty(key);
  });
  const dropDownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(dropDownRef, () => {
    setShowDropdown(false);
  });
  return (
    <div ref={dropDownRef}>
      <Dropdown
        navbar={true}
        as="li"
        autoClose={false}
        show={showDropdown}
        onToggle={(nextShow) => {
          setShowDropdown(nextShow);
        }}
      >
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          className="pe-0 ps-2 nav-link"
        >
          <span className="d-flex align-middle">
            <SoftBadge bg="info" className="me-1" pill>
              {SESSION_ENVS[firstEnvAvailable]}
            </SoftBadge>
            {currentSessions[firstEnvAvailable].user.email}
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          renderOnMount
          className="dropdown-caret dropdown-menu-card dropdown-menu-end"
        >
          {Object.keys(SESSION_ENVS).map((env) => {
            if (!DEVELOPMENT && env === 'development') return null;
            return (
              <div key={env}>
                <Dropdown.Item className="bg-white text-center p-2">
                  {!isEmpty(currentSessions[env]) ? (
                    <span className="d-flex align-middle">
                      <SoftBadge className="me-1 fs--2" bg="info" pill>
                        {SESSION_ENVS[env]}
                      </SoftBadge>
                      {currentSessions[firstEnvAvailable].user.email}
                      <SessionSignOutButton env={env} />
                    </span>
                  ) : (
                    <SessionSignInButton env={env} />
                  )}
                </Dropdown.Item>
              </div>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default NavigationUserMenu;
