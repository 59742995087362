import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import Button from 'components/ui/button';
import isNil from 'lodash/isNil';
import { useLansky } from 'services/lansky/hook';
import { useI18n } from 'services/i18n/hook';
import { useFile } from 'services/file/hook';
import EnvironmentSelectField from 'components/environment/select_field';
import { DEVELOPMENT } from 'constants';

const LanskySelectorForm = () => {
  const { t } = useI18n();
  const { selectFile } = useFile();
  const [file, setFile] = useState(null);
  const { loading, setEnv, env, runLansky } = useLansky();
  const getFile = async () => {
    const selectedFile = await selectFile((file) => runLansky(file));
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  const compute = (version) => {
    runLansky(file, version).then(() => {
      //setFile(null);
    });
  };
  return (
    <div className="p-2 bg-200">
      <InputGroup className="mb-2">
        <Button
          variant="secondary"
          size="sm"
          onClick={getFile}
          icon={['fa', 'file-excel']}
        >
          {t('components.lansky.selector.form.select_file')}
        </Button>
        <Form.Control
          size="sm"
          placeholder={t(
            'components.lansky.selector.form.no_computer_selected',
          )}
          value={file ? file.name : ''}
          disabled
          onChange={() => {}}
        />
      </InputGroup>
      <InputGroup>
        <EnvironmentSelectField env={env} setEnv={setEnv} loading={loading} />
        <Button
          variant="primary"
          size="sm"
          onClick={() => compute()}
          disabled={isNil(file)}
          icon={['fa', 'calculator']}
        >
          {t('components.lansky.selector.form.compute')}
        </Button>
        {DEVELOPMENT && (
          <Button
            variant="primary"
            size="sm"
            onClick={() => compute(2)}
            disabled={isNil(file)}
            icon={['fa', 'calculator']}
          >
            {t('components.lansky.selector.form.compute2')}
          </Button>
        )}
      </InputGroup>
    </div>
  );
};

export default LanskySelectorForm;
