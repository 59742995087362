import React, { useEffect, useMemo, useState, useCallback } from 'react';
import ReactFlow, {
  Controls,
  Background,
  applyNodeChanges,
  applyEdgeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { SmartBezierEdge } from '@tisoap/react-flow-smart-edge';
import { getChangeStatusOperations } from 'components/workflow/statuses/table';
import { useWorkflow } from 'services/workflow/hook';
import Workflow from 'services/workflow';
import get from 'lodash/get';
import compact from 'lodash/compact';
import uniqBy from 'lodash/uniqBy';
import flattenDeep from 'lodash/flattenDeep';
import WorkflowFLowNode from 'components/workflow/flow/node';

const getChangeStatusFromBlock = (
  statuses,
  workflow,
  product,
  forSeller,
  statusIndex,
) => {
  let changeStatus = [];
  const status = statuses[statusIndex];
  const wf = new Workflow({
    product,
    workflow,
    authorizations: {
      edit_data: true,
    },
    enriched_data: {
      status,
      current_user: {
        provider: false,
      },
    },
  });
  const businessSteps = wf.getNavigation();
  for (
    let businessStepIndex = 0;
    businessStepIndex < businessSteps.length;
    businessStepIndex++
  ) {
    const businessStep = businessSteps[businessStepIndex];
    if (
      wf.isStepVisible(businessStep) &&
      forSeller &&
      !businessStep.backoffice_only
    ) {
      for (
        let subStepIndex = 0;
        subStepIndex < businessStep.steps.length;
        subStepIndex++
      ) {
        const subStep = businessStep.steps[subStepIndex];
        if (
          wf.isStepVisible(subStep) &&
          forSeller &&
          !subStep.backoffice_only
        ) {
          const stepBlocks = wf.getBlocksOfStep(subStep);
          const changeStatusBlocks = compact(
            stepBlocks.map((block) => {
              const operations = get(block, 'operations', []).filter(
                (operation) => operation.kind === 'change_status',
              );
              if (operations.length > 0) {
                return {
                  block,
                  operations,
                };
              }
            }),
          );
          const blockChangeStatus = changeStatusBlocks.filter(
            ({ block }) => block.kind !== 'next_step',
          );
          blockChangeStatus.forEach((changeStatusBlock) => {
            const targetStatusIndex = statuses.findIndex(
              (status) =>
                status.id === changeStatusBlock.operations[0].status_id,
            );
            if (targetStatusIndex === -1) {
              return;
            }
            changeStatus.push({
              from: statuses[statusIndex],
              to: {
                businessStep,
                subStep,
                block: changeStatusBlock.block,
                status: statuses[targetStatusIndex],
              },
            });
          });
        }
      }
    }
  }
  return changeStatus;
};

const walkTroughWorkflow = (statuses, workflow, product, forSeller = true) => {
  let changeStatus = [];
  let statusIndex = 0;
  const status = statuses[statusIndex];
  const wf = new Workflow({
    product,
    workflow,
    authorizations: {
      edit_data: true,
    },
    enriched_data: {
      status,
      current_user: {
        provider: false,
      },
    },
  });
  const businessSteps = wf.getNavigation();
  for (
    let businessStepIndex = 0;
    businessStepIndex < businessSteps.length;
    businessStepIndex++
  ) {
    const businessStep = businessSteps[businessStepIndex];
    if (
      wf.isStepVisible(businessStep) &&
      forSeller &&
      !businessStep.backoffice_only
    ) {
      for (
        let subStepIndex = 0;
        subStepIndex < businessStep.steps.length;
        subStepIndex++
      ) {
        const subStep = businessStep.steps[subStepIndex];
        if (
          wf.isStepVisible(subStep) &&
          forSeller &&
          !subStep.backoffice_only
        ) {
          const stepBlocks = wf.getBlocksOfStep(subStep);
          const changeStatusBlocks = compact(
            stepBlocks.map((block) => {
              const operations = get(block, 'operations', []).filter(
                (operation) => operation.kind === 'change_status',
              );
              if (operations.length > 0) {
                return {
                  block,
                  operations,
                };
              }
            }),
          );
          const nextStepChangeStatus = changeStatusBlocks.find(
            ({ block }) => block.kind === 'next_step',
          );
          const blockChangeStatus = changeStatusBlocks.filter(
            ({ block }) => block.kind !== 'next_step',
          );

          if (nextStepChangeStatus && nextStepChangeStatus.operations.length) {
            const targetStatusIndex = statuses.findIndex(
              (status) =>
                status.id === nextStepChangeStatus.operations[0].status_id,
            );
            if (targetStatusIndex === -1) {
              return;
            }
            changeStatus.push({
              from: statuses[statusIndex],
              to: {
                businessStep,
                subStep,
                block: nextStepChangeStatus.block,
                status: statuses[targetStatusIndex],
              },
            });
            wf.request.enriched_data.status = statuses[targetStatusIndex];
            statusIndex = targetStatusIndex;
          }
          if (blockChangeStatus.length) {
            blockChangeStatus.forEach((blockChangeStatus) => {
              const targetStatusIndex = statuses.findIndex(
                (status) =>
                  status.id === blockChangeStatus.operations[0].status_id,
              );
              if (targetStatusIndex === -1) {
                return;
              }
              changeStatus.push({
                from: statuses[statusIndex],
                to: {
                  businessStep,
                  subStep,
                  block: blockChangeStatus.block,
                  status: statuses[targetStatusIndex],
                },
              });
              changeStatus = [
                ...changeStatus,
                ...getChangeStatusFromBlock(
                  statuses,
                  workflow,
                  product,
                  forSeller,
                  targetStatusIndex,
                ),
              ];
            });
          }
        }
      }
    }
  }
  return changeStatus;
};

const nodeTypes = { statusNode: WorkflowFLowNode };
const edgeTypes = { smart: SmartBezierEdge };

const Flow = () => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [init, setInit] = useState(false);
  const { selectedWorkflow } = useWorkflow();
  useEffect(() => {
    const statusesSeller = getChangeStatusOperations({
      workflow: selectedWorkflow.workflow,
      statuses: selectedWorkflow.statuses,
      orderFor: 'seller',
    });
    const statusChange = walkTroughWorkflow(
      statusesSeller,
      selectedWorkflow.workflow,
      selectedWorkflow.product,
    );
    const initialNodes = statusesSeller.map((status, index) => {
      return {
        id: `${index}`,
        data: {
          label: status.slug,
          status_id: status.id,
          status,
        },
        position: {
          x: 0,
          y: index * 100,
        },
        type: 'statusNode',
        draggable: true,
      };
    });
    const initialEdges = uniqBy(
      compact(
        flattenDeep(
          statusChange.map((change, index) => {
            const fromNode = initialNodes.find(
              (node) => node.data.status_id === change.from.id,
            );
            const toNode = initialNodes.find(
              (node) => node.data.status_id === change.to.status.id,
            );
            return {
              id: `${fromNode.data.label}--->${toNode.data.label}`,
              source: fromNode.id,
              target: toNode.id,
              elevateEdgesOnSelect: true,
              animated: true,
              type: 'smart',
            };
          }),
        ),
      ),
      'id',
    );
    setNodes(initialNodes);
    setEdges(initialEdges);
    setInit(true);
  }, []);
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [],
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [],
  );
  if (!init) {
    return null;
  }
  return (
    <div style={{ height: '500px' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default Flow;
