import React from 'react';
import isArray from 'lodash/isArray';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import { useI18n } from 'services/i18n/hook';

const FormFieldError = (props) => {
  const { t } = useI18n();
  const { error, warning, touched, className } = props;
  if (!touched) {
    return null;
  }
  const checkError = (err) => {
    if (isObject(err) && !isNil(err.t)) {
      return t(err.t, err.params);
    }
    return err;
  };

  const getErrors = () => {
    if (isArray(error)) {
      return error.map(checkError).join(', ');
    }
    if (isObject(error)) {
      return checkError(error);
    }
    return error;
  };

  return (
    <React.Fragment>
      {error && (
        <Form.Control.Feedback type="invalid" className={className}>
          {getErrors(error)}
        </Form.Control.Feedback>
      )}
      {warning && (
        <Form.Control.Feedback
          className={classNames('field-warning', className)}
        >
          {getErrors(warning)}
        </Form.Control.Feedback>
      )}
    </React.Fragment>
  );
};

export default FormFieldError;
