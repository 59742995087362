import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RequestDataCard from 'components/request/data/card';
import RequestProvider from 'services/request/provider';
import FileProvider from 'services/file/provider';
import WatsonCard from 'components/watson/card';
import WatsonProvider from 'services/watson/provider';
import WatsonDataPathActions from 'components/watson/data_path_actions';
import WorkflowProvider from 'services/workflow/provider';

const ScenesWatsonBuilder = () => {
  return (
    <WorkflowProvider>
      <FileProvider>
        <RequestProvider>
          <WatsonProvider>
            <Row className="g-3 mb-3">
              <Col md={6}>
                <RequestDataCard
                  dataActions={WatsonDataPathActions}
                  includeTranslations
                />
              </Col>
              <Col md={6}>
                <WatsonCard />
              </Col>
            </Row>
          </WatsonProvider>
        </RequestProvider>
      </FileProvider>
    </WorkflowProvider>
  );
};

export default ScenesWatsonBuilder;
