import axios from 'axios';
import { createPresignedPost } from '@aws-sdk/s3-presigned-post';
import { S3Client } from '@aws-sdk/client-s3';

export const getSignedUrl = async ({ fileName }) => {
  const bucketName = process.env.REACT_APP_AWS_BUCKET;
  const Key = fileName;

  const Conditions = [
    { acl: 'public-read' },
    { bucket: bucketName },
    ['eq', '$acl', 'public-read'],
    ['content-length-range', 1, 1024 * 1024 * 2],
  ];

  const Fields = {
    acl: 'public-read',
  };

  const params = {
    Bucket: bucketName,
    Key,
    Conditions,
    Fields,
    Expires: 15,
  };

  const client = new S3Client({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    },
  });

  try {
    const { url, fields } = await createPresignedPost(client, params);
    return { uploadUrl: url, fields };
  } catch (error) {
    console.error(error);
  }
};

export const uploadToS3 = async ({ file, onProgressChange = () => {} }) => {
  const formData = new FormData();
  const fileName = `report_${Date.now()}.docx`;
  const { uploadUrl, fields } = await getSignedUrl({
    fileName,
  });
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });
  formData.append('file', file, file?.name);

  const parseProgress = (progressEvent) => {
    const progressPercentage =
      (progressEvent.loaded / progressEvent.total) * 100;
    onProgressChange(progressPercentage);
  };

  try {
    await axios.post(uploadUrl, formData, {
      onUploadProgress: parseProgress,
    });
    return `${uploadUrl}${fileName}`;
  } catch (error) {
    throw error;
  }
};
