import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import ScenesSessionLogin from 'scenes/session/login';
import ScenesError500 from 'scenes/error/500';
import PublicRoot from 'components/public/root';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PublicRoot />,
    children: [
      {
        index: true,
        element: <ScenesSessionLogin />,
      },
    ],
    errorElement: <ScenesError500 />,
  },
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

export default router;
