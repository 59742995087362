import React from 'react';
import { Form } from 'react-bootstrap';
import FormFieldError from 'components/ui/form/field_error';

const FormInput = ({
  placeholder,
  label,
  type = 'text',
  input: { onChange, value, name, ...restInput },
  meta,
  ...restProps
}) => {
  return (
    <Form.Group className="mb-3">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        isInvalid={meta.touched && meta.error}
        placeholder={placeholder || label}
        value={value}
        name={name}
        onChange={onChange}
        type={type}
        {...restInput}
        {...restProps}
      />
      <FormFieldError {...meta} />
    </Form.Group>
  );
};

export default FormInput;
