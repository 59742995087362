import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { Alert, Card, Col, InputGroup, Row } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import LanskySelectorForm from 'components/lansky/selector/form';
import LanskyResults from 'components/lansky/results';
import Moment from 'react-moment';
import { useLansky } from 'services/lansky/hook';
import Loader from 'components/ui/loader';
import EmptyLanskyImage from 'images/lansky_empty.svg';
import EmptyState from 'components/ui/empty_state';
import { useRequest } from 'services/request/hook';
import { getWorkflow } from 'requests/workflow';
import { DEVELOPMENT } from 'constants';

const LanskyCard = () => {
  const { request, env } = useRequest();
  const { t } = useI18n();
  const { runAt, errors, loading, results } = useLansky();

  const [workflow, setWorkflow] = useState(null);
  const [computersOfWorkfklow, setComputersOfWorkflow] = useState([]);

  const profiler = results?.__debug?.profiler;

  useEffect(() => {
    if (!request?.workflow_id) {
      return;
    }
    if (DEVELOPMENT) {
      getWorkflow(request.workflow_id, env).then((response) => {
        const workflow = response.data.workflow;
        const computers = [];
        workflow.steps.forEach((businessStep) => {
          businessStep.steps.forEach((step) => {
            if (step.blocks.length > 0) {
              step.blocks.forEach((block) => {
                const computerURL = get(block, 'computer_url', null);
                if (!isEmpty(computerURL)) {
                  computers.push({
                    url: computerURL,
                    computer: get(block, 'computer'),
                  });
                }
              });
            }
          });
        });
        setComputersOfWorkflow(computers);
      });
    }
  }, [request?.workflow_id]);

  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div>
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {t('components.lansky.card.computer_title')}
              </h5>
              <small className="d-flex align-items-center">
                {runAt && (
                  <>
                    {t('components.lansky.card.last_computer_run_at')}
                    <Moment from={runAt} format="DD/MM/YYYY HH:mm" />
                  </>
                )}
                {profiler && (
                  <span className="ms-3 p-1 bg-200">
                    {Object.keys(profiler)
                      .map((key) => `${key} : ${profiler[key]}`)
                      .join(', ')}{' '}
                    {`=> Total : `}
                    {Object.values(profiler).reduce(
                      (sum, value) => sum + value,
                      0,
                    )}
                  </span>
                )}
              </small>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {DEVELOPMENT && false && (
          <InputGroup>
            <Form.Select
              size="sm"
              disabled={loading}
              value={env}
              id="computer_to_use"
              style={{
                width: '160px',
              }}
              onChange={(ev) => {
                const newEnv = ev.target.value;

                setEnv(newEnv);
              }}
            >
              {computersOfWorkfklow.map(({ computer, url }) => (
                <option value={url} key={url}>
                  {computer.name}
                </option>
              ))}
            </Form.Select>
          </InputGroup>
        )}
        <LanskySelectorForm />
        <div className="p-2">
          {loading ? (
            <Loader />
          ) : (
            <>
              {errors ? (
                <Alert variant="danger" className="mt-2">
                  {t('components.lansky.card.error_occurred')}
                  <pre>{errors}</pre>
                </Alert>
              ) : (
                <>
                  {!isEmpty(results) ? (
                    <LanskyResults />
                  ) : (
                    <EmptyState
                      image={EmptyLanskyImage}
                      text={t(
                        'components.lansky.card.select_computer_and_compute',
                      )}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default LanskyCard;
