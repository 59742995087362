import React, { useState } from 'react';
import LanskyContext from 'services/lansky/context';
import { DEFAULT_ENV, LANSKY_ENVS } from 'constants';
import { readFile } from 'services/file';
import { useRequest } from 'services/request/hook';
import axios from 'axios';
import { useSession } from 'services/sessions/hook';
import get from 'lodash/get';
import { listLanskyInputs } from 'services/schema/parse_lansky';

const LanskyProvider = ({ children }) => {
  const { request, setRequestDataFilter } = useRequest();
  const [env, setEnv] = useState(DEFAULT_ENV);
  const [errors, setErrors] = useState(null);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [runAt, setRunAt] = useState(false);
  const [onlyLanskyInputs, setOnlyLanskyInputs] = useState(false);
  const [lanskyInputs, setLanskyInputs] = useState([]);
  const { currentSessions } = useSession();
  const runLansky = async (file, lanskyVersion) => {
    setLoading(true);
    setErrors(null);
    setResults(null);
    const fileData = await readFile(file);
    const msToken = get(currentSessions, `${env}.authorizationToken`, null);
    console.log('version', lanskyVersion);
    try {
      const response = await axios.post(
        `${LANSKY_ENVS[env].path}/run${lanskyVersion ? lanskyVersion : ''}`,
        {
          file: fileData,
          data: request,
          options: {
            debug: true,
            readOptions: {
              type: 'binary',
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${msToken}`,
            Environment: env,
          },
        },
      );
      setRunAt(Date.now());
      setResults(response.data);
      setLoading(false);
      const { inputs } = await listLanskyInputs(
        { file: fileData },
        env,
        currentSessions,
        {
          options: {
            debug: true,
            readOptions: {
              type: 'binary',
            },
          },
        },
      );
      setLanskyInputs(inputs);
    } catch (e) {
      setErrors(e.response?.data || e.message);
      setLoading(false);
    }
  };
  const toggleLanksyInputs = () => {
    const newOnlyInputs = !onlyLanskyInputs;
    setRequestDataFilter(newOnlyInputs ? lanskyInputs : []);
    setOnlyLanskyInputs(newOnlyInputs);
  };
  return (
    <LanskyContext.Provider
      value={{
        runLansky,
        errors,
        results,
        loading,
        setEnv,
        env,
        runAt,
        toggleLanksyInputs,
        onlyLanskyInputs,
      }}
    >
      {children}
    </LanskyContext.Provider>
  );
};

export default LanskyProvider;
