import React from 'react';
import Card from 'components/ui/card';
import { useI18n } from 'services/i18n/hook';
import WorkflowSelector from 'components/workflow/selector';
import { useWorkflow } from 'services/workflow/hook';
import Loader from 'components/ui/loader';
import WorkflowTranslationsTable from 'components/workflow/translations/table';
import EmptyState from 'components/ui/empty_state';
import WorkflowTranslationEmpty from 'images/workflow_translation_empty.svg';

const WorkflowTranslations = () => {
  const { t } = useI18n();
  const { selectedWorkflow, loading } = useWorkflow();
  return (
    <Card
      id="translation-card-header"
      title={
        <h5>
          {t('components.workflow.translations.index.check_translations')}
        </h5>
      }
      actions={<WorkflowSelector />}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedWorkflow && selectedWorkflow.workflow.id ? (
            <WorkflowTranslationsTable />
          ) : (
            <EmptyState
              image={WorkflowTranslationEmpty}
              text={t(
                'components.workflow.translations.index.select_a_workflow_to_edit_translations',
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default WorkflowTranslations;
