import React, { useEffect, useState } from 'react';
import I18nProvider from 'services/i18n/provider';
import { HelmetProvider } from 'react-helmet-async';
import PageHead from 'components/ui/page_head';
import AppMainContainer from './app/main/container';
import AddinContainer from 'app/addin/container';

import 'bootstrap/dist/css/bootstrap.min.css';
import './application.css';
import SessionProvider from 'services/sessions/provider';
import ApplicationProvider from 'services/application/provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'services/icons';
import WithIcons from 'services/icons';

const InsurgateTools = () => {
  const [checkingForTools2, setCheckingForTools2] = useState(true);
  const [addinContext, setAddinContext] = useState(null);

  const checkForTools2 = () => {
    if (window.location.host.startsWith('tools2')) {
      window.location = 'https://tools.insurgate.com';
    } else {
      setCheckingForTools2(false);
    }
  };

  const checkForAddinContext = () => {
    // check url params for addin context
    const urlParams = new URLSearchParams(window.location.search);
    const addinParam = urlParams.get('_host_Info');
    if (addinParam && addinParam.length > 0) {
      sessionStorage.setItem('addin', addinParam);
      setAddinContext(addinParam);
    } else {
      if (sessionStorage.getItem('addin')) {
        setAddinContext(sessionStorage.getItem('addin'));
      }
    }
  };

  useEffect(checkForAddinContext, []);
  useEffect(checkForTools2, []);

  if (checkingForTools2) {
    return null;
  }

  return (
    <WithIcons>
      <HelmetProvider>
        <ToastContainer />
        <I18nProvider>
          <SessionProvider>
            <ApplicationProvider addinContext={addinContext}>
              {!addinContext ? (
                <>
                  <PageHead />
                  <AppMainContainer />
                </>
              ) : (
                <AddinContainer />
              )}
            </ApplicationProvider>
          </SessionProvider>
        </I18nProvider>
      </HelmetProvider>
    </WithIcons>
  );
};

export default InsurgateTools;
