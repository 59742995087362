import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

const Loader = (props) => (
  <Row className="flex-center py-5">
    <Col xs="auto">
      <Spinner animation="border" role="status" {...props} />
    </Col>
  </Row>
);
export default Loader;
