import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useI18n } from 'services/i18n/hook';
import Button from 'components/ui/button';
import get from 'lodash/get';
import FormInput from 'components/ui/form/input';

const isStringHTML = (str) => {
  const a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }

  return false;
};

const WorkflowTranslationsForm = ({
  translationKey,
  allTranslations,
  locales,
  close,
  apply,
}) => {
  const { t } = useI18n();
  const [isHTML, setIsHTML] = useState(false);
  const [formValues, setFormValues] = useState({
    key: translationKey,
  });

  const onChange = (name, value) => {
    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        [name]: value,
      };
    });
  };

  const setInitialFormValues = () => {
    let translationIsHTML = false;
    const initialValues = {
      initialKey: translationKey,
      key: translationKey,
    };
    locales.forEach((locale) => {
      initialValues[locale] = get(
        allTranslations,
        `${translationKey}.${locale}`,
        '',
      );
      if (!translationIsHTML) {
        translationIsHTML = isStringHTML(initialValues[locale]);
      }
    });
    setFormValues(initialValues);
    if (translationIsHTML) {
      setIsHTML(true);
    }
  };

  useEffect(() => {
    setInitialFormValues();
  }, []);

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label>
          {t('components.workflow.translations.form.translation_key')}
        </Form.Label>
        <Form.Control
          size="sm"
          placeholder={t(
            'components.workflow.translations.form.translation_key',
          )}
          value={formValues.key}
          onChange={(ev) => onChange('key', ev.target.value)}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          type="switch"
          id="translation_as_html"
          label={t('components.workflow.translations.form.format_content')}
          checked={isHTML}
          onChange={() => {
            const newIsHTML = !isHTML;
            if (!newIsHTML) {
              setFormValues((prevFormValues) => {
                return Object.fromEntries(
                  Object.entries(prevFormValues).map((entry) => {
                    return entry.map((key) =>
                      key.replace(/<\/?[^>]+(>|$)/g, ''),
                    );
                  }),
                );
              });
            }
            setIsHTML(newIsHTML);
          }}
        />
      </Form.Group>
      {locales.map((locale) => (
        <Form.Group className="mb-2" key={locale}>
          <Form.Label>
            {t(`components.workflow.translations.form.${locale}_translation`)}
          </Form.Label>
          {isHTML ? (
            <ReactQuill
              className="bg-white"
              theme="snow"
              value={formValues[locale]}
              onChange={(newTemplate) => {
                onChange(locale, newTemplate);
              }}
            />
          ) : (
            <FormInput
              meta={{}}
              input={{
                onChange: (ev) => {
                  onChange(locale, ev.target.value);
                },
                value: formValues[locale],
              }}
            />
          )}
        </Form.Group>
      ))}
      <div className="d-flex align-items-center justify-content-between">
        <Button size="sm" variant="secondary" onClick={close}>
          {t('components.workflow.translations.form.cancel')}
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            apply(formValues);
            close();
          }}
        >
          {t('components.workflow.translations.form.apply')}
        </Button>
      </div>
    </>
  );
};

export default WorkflowTranslationsForm;
