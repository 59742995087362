import React from 'react';
import classNames from 'classnames';
import { useApplication } from 'services/application/hook';

const RequestNavigationItem = ({ number, step, className }) => {
  const { getAttribute } = useApplication();
  return (
    <>
      <span className={classNames('me-1', className)}>{number}</span>
      {getAttribute(step, 'name')}
    </>
  );
};

export default RequestNavigationItem;
