import React, { useState } from 'react';
import { API_DOMAINS } from 'constants';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { getAuthorizationToken } from 'services/sessions/authorization_token';
import { DEVELOPMENT } from 'constants';

const EnvironmentSelectField = ({ env, setEnv, loading }) => {
  const { t } = useI18n();
  const [error, setError] = useState(null);
  const renderError = (props) => {
    if (!!error) {
      return (
        <Tooltip {...props} id="environment_select">
          <span className="text-danger">{error}</span>
        </Tooltip>
      );
    }
    return <div />;
  };
  return (
    <OverlayTrigger placement="bottom" overlay={renderError}>
      <Form.Select
        size="sm"
        placeholer={t('global.environment')}
        disabled={loading}
        value={env}
        id="environment_select"
        isInvalid={!!error}
        style={{
          width: '160px',
        }}
        onChange={(ev) => {
          const newEnv = ev.target.value;
          if (!getAuthorizationToken(newEnv)) {
            setError(
              t('components.environment.select_field.no_token_error', {
                env: newEnv,
              }),
            );
          } else {
            setError(null);
          }
          setEnv(newEnv);
        }}
      >
        {Object.keys(API_DOMAINS)
          .filter((env) => (!DEVELOPMENT ? env !== 'development' : true))
          .map((env) => (
            <option value={env} key={env}>
              {t(`global.env.${env}`)}
            </option>
          ))}
      </Form.Select>
    </OverlayTrigger>
  );
};

export default EnvironmentSelectField;
