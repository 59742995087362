import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { availableMethods } from 'components/watson/documentation';
import Button from 'components/ui/button';
import './data_path_actions.scss';
import VariableUsagePathActionButton from 'components/workflow/variable_usage/path_action_button';
import { useApplication } from 'services/application/hook';

const WatsonDataPathActions = ({ path, onPathSelect }) => {
  const { inIframe } = useApplication();
  return (
    <ButtonGroup
      size="sm"
      className={!inIframe ? 'data-action-group' : 'data-action-button'}
    >
      <VariableUsagePathActionButton path={path} />
      {availableMethods
        .filter((method) => !!method.copy)
        .map((method, index) => (
          <Button
            key={index}
            size="sm"
            variant="outline-secondary"
            icon={method.copyIcon}
            onClick={() => method.copy(path, onPathSelect)}
          />
        ))}
    </ButtonGroup>
  );
};

export default WatsonDataPathActions;
