import React from 'react';
import Card from 'components/ui/card';
import { useI18n } from 'services/i18n/hook';
import Loader from 'components/ui/loader';
import EmptyState from 'components/ui/empty_state';
import WorkflowTranslationEmpty from 'images/workflow_translation_empty.svg';
import { useRequest } from 'services/request/hook';
import RequestSearchForm from 'components/request/search/form';
import RequestNavigationMenu from 'components/request/navigation/menu';

const RequestNavigation = () => {
  const { t } = useI18n();
  const { request, loading } = useRequest();

  return (
    <Card
      title={<h5>{t('components.request.navigation.index.title')}</h5>}
      actions={<RequestSearchForm />}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {request && request.id ? (
            <div className="p-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h3>{request.name}</h3>
              </div>
              <RequestNavigationMenu />
            </div>
          ) : (
            <EmptyState
              image={WorkflowTranslationEmpty}
              text={t(
                'components.request.navigation.index.get_request_to_debug_navigation',
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default RequestNavigation;
