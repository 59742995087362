import React, { useState } from 'react';
import RequestProvider from 'services/request/provider';
import AddinWatsonBuilderRequest from './request';
import { Tabs, Tab } from 'react-bootstrap';
import WatsonPreview from 'components/watson/preview';
import WatsonProvider from 'services/watson/provider';

const AddinWatsonBuilder = () => {
  const [activeTab, setActiveTab] = useState('request');

  return (
    <RequestProvider>
      <WatsonProvider>
        <div className="d-flex flex-column h-100">
          <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
            <Tab eventKey="request" title="Request" className="mt-2 bg-light">
              {activeTab === 'request' && <AddinWatsonBuilderRequest />}
            </Tab>
            <Tab eventKey="preview" title="Preview" className="mt-2">
              {activeTab === 'preview' && <WatsonPreview />}
            </Tab>
          </Tabs>
        </div>
      </WatsonProvider>
    </RequestProvider>
  );
};

export default AddinWatsonBuilder;
