import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { generateParams } from 'services/url';
import { useRequest } from 'services/request/hook';
import APIDebugContext from 'services/api_debug/context';
import { useSearchParams } from 'react-router-dom';

const ApiDebugProvider = ({ children }) => {
  const { request } = useRequest();
  const [errors, setErrors] = useState(null);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [runAt, setRunAt] = useState(false);
  const [apiPath, setApiPath] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const apiPathSearchParams = searchParams.get('apiPath');
  const callApi = (path) => {
    setResults({});
    setErrors(null);
    setLoading(true);
    axios
      .post(
        path || apiPath,
        { data: request },
        {
          auth: {
            username: 'insurgate',
            password: 'insurgate',
          },
        },
      )
      .then((response) => {
        setSearchParams(generateParams({ apiPath }, searchParams));
        setLoading(false);
        setResults(response.data);
        setRunAt(Date.now());
      })
      .catch((e) => {
        console.error('got error', e.response.data);
        setLoading(false);
        setErrors(e.response.data.message || e.message);
      });
  };

  useEffect(() => {
    if (apiPathSearchParams) {
      setApiPath(apiPathSearchParams);
    }
  }, []);

  return (
    <APIDebugContext.Provider
      value={{
        callApi,
        loading,
        errors,
        results,
        setApiPath,
        apiPath,
        runAt,
      }}
    >
      {children}
    </APIDebugContext.Provider>
  );
};

export default ApiDebugProvider;
