import React, { useState, useEffect } from 'react';
import { I18n } from 'i18n-js';
import I18nContext from 'services/i18n/context';
import moment from 'moment';
import Moment from 'react-moment';
import isNil from 'lodash/isNil';
import localesEn from 'locales/en.yml';
import {
  DEFAULT_LOCALE,
  DEVELOPMENT,
  COOKIE_DOMAIN,
  PROTOCOL,
  COOKIES_EXPIRES_IN_DAYS,
} from 'constants';
import Loader from 'components/ui/loader';

Moment.globalMoment = moment;
import Cookies from 'js-cookie';

const COOKIE_NAME = 'locale';

const i18n = new I18n();

export const setCookieLocale = (locale) => {
  Cookies.set(COOKIE_NAME, locale, {
    domain: COOKIE_DOMAIN,
    secure: PROTOCOL === 'https',
    expires: COOKIES_EXPIRES_IN_DAYS,
  });
};

export const getCookieLocale = () => {
  return 'en';
  //return Cookies.get(COOKIE_NAME);
};

const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState(getCookieLocale());
  const [i18nInstance, setI18nInstance] = useState(null);

  const getLocale = () => {
    const cookieLocale = getCookieLocale();
    return isNil(i18nInstance.locale)
      ? isNil(cookieLocale)
        ? DEFAULT_LOCALE
        : cookieLocale
      : i18nInstance.locale;
  };

  const t = (text, params) => {
    return i18nInstance.t(text, { ...params });
  };

  const t_html = (text, params) => {
    const trusted = {
      __html: i18nInstance.t(text, { ...params }),
    };
    /* eslint-disable react/no-danger */
    return <span dangerouslySetInnerHTML={trusted} />;
    /* eslint-enable */
  };

  const initI18nInstance = () => {
    const cookieLocale = getCookieLocale();
    i18n.store({
      en: localesEn.en,
    });
    const localeToInit = isNil(cookieLocale) ? DEFAULT_LOCALE : cookieLocale;
    Moment.globalLocale = localeToInit;
    i18n.defaultLocale = localeToInit;
    i18n.locale = localeToInit;

    setI18nInstance(i18n);
    setCookieLocale(localeToInit);
    if (DEVELOPMENT) {
      window.i18n = i18n;
    }
  };

  useEffect(() => {
    initI18nInstance();
  }, []);

  useEffect(() => {
    if (isNil(i18nInstance) || isNil(locale)) {
      return;
    }
    if (i18nInstance.locale !== locale) {
      window.locale = locale;
      Moment.globalLocale = locale;
      i18nInstance.locale = locale;
      setCookieLocale(locale);
      setI18nInstance(i18nInstance);
    }
  }, [locale, i18nInstance]);

  if (i18nInstance === null) {
    return <Loader />;
  }

  return (
    <I18nContext.Provider
      key={`current-locale-${locale}`}
      value={{
        getCurrentLocale: getLocale,
        setCurrentLocale: (localeToSet) => {
          setLocale(localeToSet);
          setCookieLocale(localeToSet);
        },
        t,
        t_html,
      }}
    >
      {children}
    </I18nContext.Provider>
  );
};

export default I18nProvider;
