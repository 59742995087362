import React, { useRef, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { useI18n } from 'services/i18n/hook';
import EmbedBuilderForm from 'components/embed_builder/form';
import EmptyState from 'components/ui/empty_state';
import ConfigureImage from 'images/configure.svg';
import { DEFAULT_ENV } from 'constants';

const EMBED_ENVS = {
  development: 'http://embed.insurgate.test',
  staging: 'https://embed.insurgate-staging.com',
  validation: 'https://embed.insurgate-validation.com',
  production: 'https://embed.insurgate.com',
};

const cleanParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(([key, value]) => {
      return !isEmpty(value);
    }),
  );
};

const EmbedBuilderContainer = () => {
  const { t } = useI18n();
  const [embedURL, setEmbedURL] = useState('');
  const [config, setConfig] = useState({
    autoSignIn: false,
    anonymousToken: '',
    locale: 'nl',
    brand: '',
    env: DEFAULT_ENV,
    width: '800',
    height: '600',
    backgroundColor: '#edf2f9',
  });
  const timeoutRef = useRef(null);

  const onChangeConfig = (newConfig) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setConfig(newConfig);
      if (isEmpty(newConfig.brand) || isEmpty(newConfig.env)) {
        setEmbedURL(null);
        return;
      }
      const { env, width, height, ...params } = newConfig;
      const urlParams = new URLSearchParams(cleanParams(params)).toString();
      let url = `${EMBED_ENVS[newConfig.env]}?${encodeURIComponent(urlParams)}`;
      setEmbedURL(url);
    }, 300);
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={4}>
          <div className="p-2 ">
            <EmbedBuilderForm onChangeConfig={onChangeConfig} config={config} />
          </div>
        </Col>
        <Col lg={8} className="p-2 d-flex align-items-center flex-column">
          {!isEmpty(embedURL) ? (
            <div>
              <div className="mb-2">
                <p className="fw-bold">
                  {t('components.embed_builder.container.embed_code')}
                </p>
                <code className="p-2 bg-100 border border-secondary text-word-break fs--2 d-flex">
                  {`<iframe
                  src="${embedURL}"
                  width="${config.width}"
                  height="${config.height}"
                />`}
                </code>
                <p className="fw-bold mt-2">
                  {t('components.embed_builder.container.embed_url')}
                </p>
                <code className="p-2 bg-100 border border-secondary text-word-break fs--2 d-flex">
                  {embedURL}
                </code>
              </div>
              <p className="fw-bold">
                {t('components.embed_builder.container.result')}
              </p>
              {
                <iframe
                  className="border border-secondary"
                  src={embedURL}
                  width={config.width}
                  height={config.height}
                  key={JSON.stringify(config)}
                />
              }
            </div>
          ) : (
            <EmptyState
              image={ConfigureImage}
              text={t('components.embed_builder.container.empty_state_title')}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default EmbedBuilderContainer;
