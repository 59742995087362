import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'components/ui/button';
import WorkflowTranslationsForm from 'components/workflow/translations/form';

const WorkflowTranslationsOpenButton = ({
  translationKey,
  locales,
  allTranslations,
  apply,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Modal show={isModalOpen} onHide={toggleModal} size="xl">
        <Modal.Header>{translationKey}</Modal.Header>
        <Modal.Body>
          <WorkflowTranslationsForm
            allTranslations={allTranslations}
            translationKey={translationKey}
            locales={locales}
            close={toggleModal}
            apply={apply}
          />
        </Modal.Body>
      </Modal>
      <Button
        size="sm"
        variant="outline-secondary"
        icon="pen-to-square"
        onClick={toggleModal}
        className="edit-translation-button"
      />
    </>
  );
};

export default WorkflowTranslationsOpenButton;
