import React from 'react';
import RequestDataCard from 'components/request/data/card';
import RequestProvider from 'services/request/provider';
import WatsonDataPathActions from 'components/watson/data_path_actions';
import OfficeProvider from 'services/office/provider';

const ScenesDataOnly = () => {
  return (
    <OfficeProvider>
      <RequestProvider>
        <RequestDataCard dataActions={WatsonDataPathActions} />
      </RequestProvider>
    </OfficeProvider>
  );
};

export default ScenesDataOnly;
