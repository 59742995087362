import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { getBrands } from 'requests/brands';
import Select from 'react-select';
import EnvironmentSelectField from 'components/environment/select_field';
import { DEFAULT_ENV, LOCALES, SELECT_STYLES } from 'constants';
import { ChromePicker } from 'react-color';
import './form.scss';

const EMBED_ENVS = [
  { value: 'development', label: 'Development' },
  { value: 'staging', label: 'Staging' },
];

const EmbedBuilderForm = ({ onChangeConfig, config }) => {
  const { t } = useI18n();
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedEnv, setSelectedEnv] = useState(EMBED_ENVS[1]);
  const [selectedLocale, setSelectedLocale] = useState({
    value: 'nl',
    label: 'nl',
  });
  const [loading, setLoading] = useState(true);
  const [embedConfig, setEmbedConfig] = useState({
    autoSignIn: false,
    anonymousToken: '',
    locale: 'nl',
    brand: '',
    env: EMBED_ENVS[1].value,
    width: '800',
    height: '600',
    backgroundColor: '#edf2f9',
  });

  const init = (env) => {
    if (!env) {
      return null;
    }
    getBrands(env)
      .then((response) => {
        const brandList = response.data.brands.map((brand) => ({
          value: brand.slug,
          label: brand.name,
        }));
        setBrands(brandList);
        setSelectedBrand(brandList[0]);
        onChangeValue('brand', brandList[0].value);
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onChangeValue = (name, value) => {
    let newConfig = {
      ...embedConfig,
      [name]: value,
    };
    if (name === 'autoSignIn' && value === true) {
      newConfig.anonymousToken = null;
    }
    setEmbedConfig(newConfig);
    onChangeConfig(newConfig);
  };

  useEffect(() => {
    init(EMBED_ENVS[1].value);
  }, []);

  return (
    <div>
      <Form.Group className="mb-2">
        <Form.Label>
          {t('components.embed_builder.form.environment')}
        </Form.Label>
        <Select
          styles={SELECT_STYLES}
          options={EMBED_ENVS}
          onChange={(newSelectedEnv) => {
            setSelectedEnv(newSelectedEnv);
            onChangeValue('env', newSelectedEnv.value);
            init(newSelectedEnv.value);
          }}
          isDisabled={loading}
          value={selectedEnv}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>{t('components.embed_builder.form.brand')}</Form.Label>
        <Select
          styles={SELECT_STYLES}
          options={brands}
          onChange={(newSelectedBrand) => {
            setSelectedBrand(newSelectedBrand);
            if (newSelectedBrand && newSelectedBrand.value) {
              onChangeValue('brand', newSelectedBrand.value);
            } else {
              onChangeValue('brand', '');
            }
          }}
          isDisabled={loading}
          value={selectedBrand || ''}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>{t('components.embed_builder.form.locale')}</Form.Label>
        <Select
          styles={SELECT_STYLES}
          options={Object.keys(LOCALES).map((locale) => ({
            value: locale,
            label: LOCALES[locale],
          }))}
          onChange={(newLocale) => {
            setSelectedLocale(newLocale);
            onChangeValue('locale', newLocale.value);
          }}
          isDisabled={loading}
          value={selectedLocale}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>{t('components.embed_builder.form.width')}</Form.Label>
        <Form.Control
          size="sm"
          placeholder={t('components.embed_builder.form.width_placeholder')}
          value={embedConfig.width}
          onChange={(ev) => onChangeValue('width', ev.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>{t('components.embed_builder.form.height')}</Form.Label>
        <Form.Control
          size="sm"
          placeholder={t('components.embed_builder.form.height_placeholder')}
          value={embedConfig.height}
          onChange={(ev) => onChangeValue('height', ev.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>
          {t('components.embed_builder.form.auto_sign_in')}
        </Form.Label>
        <Form.Check
          size="sm"
          type="switch"
          className="mb-0"
          onChange={(ev) => {
            onChangeValue('autoSignIn', ev.target.checked);
          }}
          checked={embedConfig.autoSignIn}
          label={t('components.embed_builder.form.auto_sign_in')}
        />
      </Form.Group>
      {!embedConfig.autoSignIn && (
        <>
          <Form.Group className="mb-2">
            <Form.Label>
              {t('components.embed_builder.form.anonymous_token')}
            </Form.Label>
            <Form.Control
              size="sm"
              placeholder={t('components.embed_builder.form.anonymous_token')}
              value={embedConfig.anonymousToken}
              onChange={(ev) =>
                onChangeValue('anonymousToken', ev.target.value)
              }
            />
          </Form.Group>
        </>
      )}
      <Form.Group className="mb-2">
        <Form.Label>
          {t('components.embed_builder.form.background_color')}
        </Form.Label>
        <ChromePicker
          className="border border-secondary"
          color={embedConfig.backgroundColor}
          onChange={(newColor) => {
            onChangeValue('backgroundColor', newColor.hex);
          }}
        />
      </Form.Group>
    </div>
  );
};

export default EmbedBuilderForm;
