import _ from 'lodash';
import { createConditionList, parseConditions } from 'services/conditions';
class Workflow {
  constructor(request) {
    this.request = request;
    this.product = request.product;
    this.workflow = request.workflow;
    this.steps = _.orderBy(request.workflow.steps, 'position').map(
      (businessSteps) => ({
        ...businessSteps,
        steps: _.orderBy(businessSteps.steps, 'position'),
      }),
    );
  }

  isStepVisible(step) {
    if (!step) {
      return false;
    }
    const { conditions } = parseConditions(
      step,
      'visibility_conditions.conditions',
    );
    if (!conditions.length) {
      return true;
    }
    const visibleConditionList = createConditionList(
      this.#getOnlyStatusBasedConditions(conditions),
    );
    return visibleConditionList.checkList(this.request.enriched_data).check;
  }

  isStepEditable(step) {
    if (this.#isRequestReadOnly()) {
      return false;
    }
    if (_.isNil(step)) {
      return false;
    }
    const parentStep = this.#getParentStep(step);
    if (!_.isNil(parentStep) && !this.isStepEditable(parentStep)) {
      return false;
    }
    const { conditions } = parseConditions(step, 'edit_conditions.conditions');
    if (!conditions.length) {
      return true;
    }
    const editConditionList = createConditionList(
      this.#getOnlyStatusBasedConditions(conditions),
    );
    return editConditionList.checkList(this.request.enriched_data).check;
  }

  isStepDisabled(step) {
    const parentStep = this.#getParentStep(step);
    if (!_.isNil(parentStep) && this.isStepDisabled(parentStep)) {
      return true;
    }
    const { conditions } = parseConditions(
      step,
      'disable_conditions.conditions',
    );
    if (_.isNil(step)) {
      return false;
    }
    if (!conditions.length) {
      return false;
    }
    const disableConditionList = createConditionList(
      this.#getOnlyStatusBasedConditions(conditions),
    );
    return disableConditionList.checkList(this.request.enriched_data).check;
  }

  getNavigation(forSeller = true) {
    return this.#getStepsForNavigation(this.workflow.steps, forSeller);
  }

  getBlocksOfStep(step) {
    return this.#filterBlocks(step);
  }

  //Private

  #getStepsForNavigation(steps) {
    return _.orderBy(steps, 'position').map((step) => {
      const subSteps = this.#getStepsForNavigation(step.steps);
      return {
        ...step,
        meta: {
          disabled: this.isStepDisabled(step),
          editable: this.isStepEditable(step),
        },
        steps: subSteps,
      };
    });
  }

  #filterBlocks(step) {
    if (!step || !step.blocks) {
      return [];
    }
    return _.compact(
      _.flatten(
        step.blocks.filter((block) => {
          const visibleConditions = this.#getOnlyStatusBasedConditions(
            _.get(block, 'form_schema.visibility_conditions.conditions', []),
          );
          const disableConditions = this.#getOnlyStatusBasedConditions(
            _.get(block, 'form_schema.disable_conditions.conditions', []),
          );
          const editConditions = this.#getOnlyStatusBasedConditions(
            _.get(block, 'form_schema.edit_conditions.conditions', []),
          );
          const results = {
            visible:
              visibleConditions.length > 0
                ? createConditionList(visibleConditions).checkList(
                    this.request.enriched_data,
                  ).check
                : true,
            disabled:
              disableConditions.length > 0
                ? !createConditionList(disableConditions).checkList(
                    this.request.enriched_data,
                  ).check
                : false,
            edit:
              editConditions.length > 0
                ? createConditionList(editConditions).checkList(
                    this.request.enriched_data,
                  ).check
                : true,
          };
          return results.visible && !results.disabled && results.edit;
        }),
      ),
    );
  }

  #getOnlyStatusBasedConditions(conditions) {
    if (!conditions) {
      return [];
    }
    return conditions.filter(
      (condition) =>
        _.get(condition, 'property') === 'status.slug' ||
        _.get(condition, 'property', '').includes('current_user'),
    );
  }

  #isRequestReadOnly() {
    return !this.request.authorizations.edit_data;
  }

  #getParentStep(fromStep) {
    if (_.isNil(fromStep) || _.isNil(fromStep.parent_id)) {
      return null;
    }
    return this.steps.find((step) => step.id === fromStep.parent_id);
  }
}

export default Workflow;
