import React from 'react';
import { Helmet } from 'react-helmet-async';
import favicon from 'images/favicon.png';
import { useI18n } from 'services/i18n/hook';

const PageHead = () => {
  const { getCurrentLocale } = useI18n();
  const locale = getCurrentLocale();
  return (
    <Helmet>
      <html lang={locale} />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href={favicon} type="image/png" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700|Poppins:100,200,300,400,500,600,700,800,900&display=swap"
        rel="stylesheet"
      />
      <link rel="shortcut icon" href={favicon} type="image/png" />
      <title>Insurgate - Tools</title>
    </Helmet>
  );
};

export default PageHead;
