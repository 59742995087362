import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RequestDataCard from 'components/request/data/card';
import RequestProvider from 'services/request/provider';
import ApiDebugProvider from 'services/api_debug/provider';
import APIDebugCard from 'components/api_debug/card';

const ScenesAPIDebug = () => {
  return (
    <RequestProvider>
      <ApiDebugProvider>
        <Row className="g-3 mb-3">
          <Col md={7}>
            <RequestDataCard />
          </Col>
          <Col md={5}>
            <APIDebugCard />
          </Col>
        </Row>
      </ApiDebugProvider>
    </RequestProvider>
  );
};

export default ScenesAPIDebug;
