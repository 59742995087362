import React, { useRef, useState } from 'react';
import { SESSION_ENVS } from 'constants';
import Button from 'components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { useI18n } from 'services/i18n/hook';
import { useSession } from 'services/sessions/hook';

const SessionSignOutButton = ({ env }) => {
  const { t } = useI18n();
  const { deleteSession } = useSession();
  const [showConfirm, setShowConfirm] = useState(false);
  const target = useRef(null);
  const onSignOut = (ev) => {
    ev.preventDefault();
    deleteSession(env);
  };
  const renderConfirm = (
    <Popover>
      <Popover.Body className="p-2">
        {t('components.session.sign_out.button.sign_out_from', {
          env: SESSION_ENVS[env],
        })}
        <div className="d-flex justify-content-between mt-2">
          <Button
            size="sm"
            variant="secondary"
            onClick={() => {
              setShowConfirm(false);
            }}
          >
            {t('global.cancel')}
          </Button>
          <Button size="sm" variant="danger" onClick={onSignOut}>
            {t('global.confirm')}
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="left"
        overlay={renderConfirm}
        show={showConfirm}
      >
        <span
          className="text-danger ms-2"
          ref={target.current}
          onClick={() => {
            setShowConfirm(true);
          }}
        >
          <FontAwesomeIcon icon={['fa', 'lock']} />
        </span>
      </OverlayTrigger>
    </>
  );
};

export default SessionSignOutButton;
