import React from 'react';
import { Badge } from 'react-bootstrap';

const Slug = ({ slug, children, ...restProps }) => {
  return (
    <Badge pill bg="info" {...restProps}>
      {slug}
      {children && ` (${children})`}
    </Badge>
  );
};

export default Slug;
