import React from 'react';

const LanskyContext = React.createContext({
  runLansky: () => {},
  loading: false,
  errors: null,
  results: null,
});

export default LanskyContext;
