import uniq from 'lodash/uniq';
import orderBy from 'lodash/orderBy';
import isObject from 'lodash/isObject';
import isNil from 'lodash/isNil';
import assign from 'lodash/assign';
import flatten from 'lodash/flatten';
import mergeWith from 'lodash/mergeWith';
import { getEnums } from 'services/schema/enums';
import * as Types from 'services/schema/types';
import { parseLansky } from 'services/schema/parse_lansky';
import axios from 'axios';
import { listCommands } from 'docx-templates';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { getComputer } from 'services/schema/parse_lansky';

const DEFAULT_ADDRESS_FIELDS = {
  street_name: {
    component: 'text',
    validations: ['required'],
  },
  street_number: {
    component: 'text',
    validations: ['required'],
  },
  city: {
    component: 'text',
    validations: ['required'],
    visible: [],
  },
  street_box: {
    component: 'text',
    validations: ['required'],
  },
  zip_code: {
    component: 'text',
    validations: ['required'],
  },
  lat: {
    component: 'text',
  },
  lng: {
    component: 'text',
  },
};

let unhandledComponents = [];

const mergeCopyArrays = (objValue, srcValue) => {
  return {
    ...srcValue,
    ...objValue,
  };
};

export const cleanSchema = (schema, clearedObject = {}) => {
  if (schema && isObject(schema)) {
    Object.keys(schema).map((key) => {
      if (isObject(schema[key])) {
        if (Object.keys(clearedObject[key]).length > 0) {
          clearedObject[key] = cleanSchema(schema[key], clearedObject);
        }
      } else {
        clearedObject[key] = schema[key];
      }
    });
  }
  return clearedObject;
};

export const getWorkflowLanskysInputs = async (
  workflow,
  lanskyInputs = {},
  env,
  currentSession,
) => {
  const orderedSteps = orderBy(workflow.steps, 'position');
  for (let step of orderedSteps) {
    if (step.steps && step.steps.length) {
      lanskyInputs = await getWorkflowLanskysInputs(
        { steps: step.steps },
        lanskyInputs,
        env,
        currentSession,
      );
    }
    const blocks = get(step, 'blocks', []);
    const multis = get(step, 'multis', []);
    if (multis.length) {
      multis.forEach(async (multi) => {
        const multisBlocks = get(multi, 'blocks', []);
        const orderedBlocks = orderBy(multisBlocks, 'position');
        for (let block of orderedBlocks) {
          if (block.kind === 'premiums_table' && block.computer_id) {
            const { inputs } = await parseLansky(
              getComputer(block),
              env,
              currentSession,
            );
            assign(lanskyInputs, {
              [`${multi.slug}.${block.slug}`]: {
                inputs,
                computer: block.computer,
              },
            });
          }
        }
      });
    }
    if (blocks.length) {
      const orderedBlocks = orderBy(blocks, 'position');
      for (let block of orderedBlocks) {
        if (block.kind === 'premiums_table' && block.computer_id) {
          const { inputs } = await parseLansky(
            getComputer(block),
            env,
            currentSession,
          );
          assign(lanskyInputs, {
            [block.slug]: {
              inputs,
              computer: block.computer,
            },
          });
        }
      }
    }
  }
  return lanskyInputs;
};

export const getBlocksOfKind = (steps, blockKind) => {
  let blocks = [];
  for (let step of steps) {
    if (step.steps && step.steps.length) {
      blocks = [...blocks, ...getBlocksOfKind(step.steps, blockKind)];
    }
    if (step.blocks && step.blocks.length) {
      for (let block of step.blocks) {
        if (block.kind === blockKind) {
          blocks.push(block);
        }
      }
    }
  }
  return flatten(blocks);
};

const getOperationsForKind = (steps, kind) => {
  let operations = [];
  for (let step of steps) {
    if (step.steps && step.steps.length) {
      operations = [...operations, ...getOperationsForKind(step.steps, kind)];
    }
    if (step.blocks && step.blocks.length) {
      for (let block of step.blocks) {
        if (block.kind === 'operation') {
          if (!block.operations) {
            return [];
          }
          const operationsForKind = block.operations.filter(
            (operation) => operation.kind === kind,
          );
          if (operationsForKind.length > 0) {
            operations.push({
              block,
              operations: operationsForKind,
            });
          }
        }
      }
    }
  }
  return flatten(operations);
};

const getWorkflowDocumentTemplateIds = (steps) => {
  let documentIds = [];
  for (let step of steps) {
    if (step.steps && step.steps.length) {
      documentIds = [
        ...documentIds,
        ...getWorkflowDocumentTemplateIds(step.steps),
      ];
    }
    if (step.blocks && step.blocks.length) {
      for (let block of step.blocks) {
        if (block.kind === 'operation' || block.kind === 'next_step') {
          if (!block.operations) {
            return [];
          }
          const generateTemplateOperations = block.operations.filter(
            (operation) => operation.kind === 'generate_documents',
          );
          documentIds.push({
            block,
            templateIds: flatten(
              generateTemplateOperations.map(
                (operation) => operation.document_template_ids,
              ),
            ),
          });
        }
      }
    }
  }
  return documentIds;
};

export const getEmailsOfBlocks = (steps, emails) => {
  const blockAndEmails = {};
  const operations = getOperationsForKind(steps, 'send_email');
  operations.forEach(({ block, operations }) => {
    blockAndEmails[block.slug] = operations.map((operation) =>
      emails.find((email) => email.id === operation.email_id),
    );
  });
  return blockAndEmails;
};

export const getWatsonInputs = async (
  steps,
  templateInputs = {},
  documentTemplates,
) => {
  try {
    const documentIds = getWorkflowDocumentTemplateIds(steps, templateInputs);
    for (let { block, templateIds } of documentIds) {
      templateInputs[block.slug] = [];
      for (let templateId of templateIds) {
        const template = documentTemplates.find(
          (template) => template.id === templateId,
        );
        if (template) {
          const response = await axios.get(template.template_url, {
            responseType: 'arraybuffer',
          });
          templateInputs[block.slug].push({
            template,
            inputs: await listCommands(response.data, ['{{', '}}']),
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
  return templateInputs;
};

const getSchemaOfBlocks = async (
  blocks,
  schema = {},
  env,
  currentSession,
  data,
) => {
  const orderedBlocks = orderBy(blocks, 'position');
  for (let block of orderedBlocks) {
    if (block.kind === 'operation') {
      //console.log('block', block);
    }
    if (
      (block.kind === 'premiums_table' || block.kind === 'form') &&
      !isNil(block.form_schema)
    ) {
      schema[block.slug] = parseSchema(block.form_schema.schema, data);
      if (block.kind === 'premiums_table' && block.computer_id) {
        const { outputs } = await parseLansky(
          getComputer(block),
          env,
          currentSession,
        );
        assign(schema, {
          [block.slug]: outputs,
        });
        //console.log('result', result);
      }
    }
  }
  return schema;
};

export const getStepsAvailableData = async (
  steps,
  schema = {},
  env,
  currentSession,
  data = {},
) => {
  const orderedSteps = orderBy(steps, 'position');
  for (let step of orderedSteps) {
    if (step.steps && step.steps.length) {
      schema = await getStepsAvailableData(
        step.steps,
        schema,
        env,
        currentSession,
      );
    }
    if (!isEmpty(step.multis)) {
      for (let multi of step.multis) {
        const multiSchema = await getSchemaOfBlocks(
          multi.blocks,
          {},
          env,
          currentSession,
          data,
        );
        assign(schema, {
          [`${multi.slug}[]`]: multiSchema,
        });
      }
    }
    if (!isEmpty(step.blocks)) {
      schema = await getSchemaOfBlocks(
        step.blocks,
        schema,
        env,
        currentSession,
        data,
      );
    }
  }
  return schema;
};

export const parseSchema = (schema, objectToReturn = {}, data = {}) => {
  if (!schema) {
    return objectToReturn;
  }
  const showType = true;
  Object.keys(schema).map((key) => {
    const part = schema[key];
    if (part.type && !part.component) {
      const type = part.type;
      switch (type) {
        case 'section':
          objectToReturn[key] = parseSchema(part.properties);
          return objectToReturn;
        case 'group':
          return parseSchema(part.properties, objectToReturn);
        case 'array':
          objectToReturn[`${key}[]`] = parseSchema(part.properties);
          return objectToReturn;
        case 'premiums_table':
          return objectToReturn;
        case 'title':
        case 'message':
        case 'text':
          return objectToReturn;
        default:
          console.log('unhandled type', type);
          return;
      }
    }
    if (part.component) {
      const component = part.component;
      switch (component) {
        case 'address':
          objectToReturn[key] = parseSchema(
            part.fields
              ? mergeWith(part.fields, DEFAULT_ADDRESS_FIELDS, mergeCopyArrays)
              : DEFAULT_ADDRESS_FIELDS,
          );
          break;
        case 'true_false':
          objectToReturn[key] = !showType ? null : Types.Bool;
          break;
        case 'textarea':
          objectToReturn[key] = !showType ? null : Types.Str;
          break;
        case 'multi_button':
        case 'select':
        case 'radio_list':
          objectToReturn[key] = !showType
            ? null
            : {
                ...Types.Str,
                ...getEnums(part.list, data),
              };
          break;
        case 'checkbox_list':
          objectToReturn[key] = !showType
            ? null
            : {
                ...Types.Arr,
                ...getEnums(part.list, data),
              };
          break;
        case 'date':
          objectToReturn[key] = !showType ? null : Types.Date;
          break;
        case 'range':
        case 'count':
        case 'price':
          objectToReturn[key] = !showType ? null : Types.Numb;
          break;
        case 'text':
          if (part.type && part.type === 'number') {
            objectToReturn[key] = !showType ? null : Types.Numb;
          } else {
            objectToReturn[key] = !showType ? null : Types.Str;
          }
          break;
        case 'checkbox':
          objectToReturn[key] = !showType ? null : Types.Bool;
          break;
        default:
          console.log('not handled component', component);
          unhandledComponents = uniq(unhandledComponents.push(component));
          objectToReturn[key] = !showType ? null : Types.Str;
          break;
      }
    }
  });
  return objectToReturn;
};

export let unhandled = unhandledComponents;
