import React, { useEffect, useState } from 'react';
import { useVariableUsage } from 'services/variable_usage/hook';
import Slug from 'components/ui/slug';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BlockSlug = ({ slug }) => {
  return (
    <span className="bg-light border-darker border slug fs--1">{slug}</span>
  );
};

const VariableUsageTable = ({ path }) => {
  const [usage, setUsage] = useState(null);
  const [search, setSearch] = useState(null);
  const { getVariableUsages } = useVariableUsage();

  useEffect(() => {
    let results = null;
    if (path.match(/\.(\d+)\./)) {
      const pathArray = path.split(/\.(\d+)\./);
      results = getVariableUsages(pathArray[0]);
      setSearch(pathArray[0]);
    } else {
      results = getVariableUsages(path);
      setSearch(null);
    }
    setUsage(results);
  }, []);
  if (!usage) return null;

  return (
    <>
      {search && (
        <p className="fs--1">
          <b>Path has been split</b> as it is an array iteration{' '}
          <code className="fs--1">{search}</code>
        </p>
      )}
      <ul>
        <li>
          <strong>Workflow Validation :</strong>{' '}
          {usage.workflow_validation ? (
            <FontAwesomeIcon icon={['fa', 'fa-circle-check']} />
          ) : (
            <FontAwesomeIcon icon={['fa', 'fa-circle-xmark']} />
          )}
        </li>
        {usage?.lansky && usage.lansky.length > 0 && (
          <li>
            <strong>Lansky :</strong>{' '}
            <ul>
              {usage.lansky.map(({ slug }) => (
                <li key={slug}>
                  <BlockSlug slug={slug} />
                </li>
              ))}
            </ul>
          </li>
        )}
        {usage?.watson && usage.watson.length > 0 && (
          <li>
            <strong>Watson :</strong>{' '}
            <ul>
              {usage.watson.map(({ slug, template }) => (
                <li key={slug}>
                  <BlockSlug slug={slug} />
                  <Slug slug={template.name} className="ms-1" />
                </li>
              ))}
            </ul>
          </li>
        )}
        {usage?.emails && usage.emails.length > 0 && (
          <li>
            <strong>Emails :</strong>{' '}
            <ul>
              {usage.emails.map(({ slug, usages, email }, index) => (
                <li key={index}>
                  <BlockSlug slug={slug} key={slug} />
                  <Slug slug={email.name} className="ms-1" />
                  <ul>
                    {usages.body && (
                      <li>
                        {`Body : ${Object.keys(usages.body).map(
                          (locale) =>
                            `${locale.toUpperCase()} : ${usages.body[locale]}`,
                        )}`}
                      </li>
                    )}
                    {usages.subject && (
                      <li>
                        {`Subject : ${Object.keys(usages.subject).map(
                          (locale) =>
                            `${locale.toUpperCase()} : ${
                              usages.subject[locale]
                            }`,
                        )}`}
                      </li>
                    )}
                    {usages.recipient && (
                      <li>
                        {`Recipient : ${Object.keys(usages.recipient).map(
                          (locale) =>
                            `${locale.toUpperCase()} : ${
                              usages.recipient[locale]
                            }`,
                        )}`}
                      </li>
                    )}
                  </ul>
                </li>
              ))}
            </ul>
          </li>
        )}
        {usage?.blocks && usage.blocks.length > 0 && (
          <li>
            <strong>Blocks :</strong>{' '}
            <ul>
              {usage.blocks.map(({ slug }) => (
                <li key={slug}>
                  <BlockSlug slug={slug} />
                </li>
              ))}
            </ul>
          </li>
        )}
        {usage?.translations && usage.translations.length > 0 && (
          <li>
            <strong>Translations :</strong>{' '}
            <ul>
              {usage.translations.map(({ locale, translation }, index) => (
                <li key={index}>
                  <BlockSlug slug={locale} />
                  <code className="ms-1">t:{translation}</code>
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    </>
  );
};

export default VariableUsageTable;
