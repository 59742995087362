import React, { createContext } from 'react';

const WorkflowContext = createContext({
  workflows: [],
  selectedWorkflow: null,
  setSelectedWorkflow: () => {},
  loadingWorkflows: true,
});

export default WorkflowContext;
