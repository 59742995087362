import React from 'react';

const I18nContext = React.createContext({
  t:                (key) => {
    return key;
  },
  getCurrentLocale: () => {
  },
  setCurrentLocale: () => {
  },
  t_html:           (key) => {
    return key;
  },
});

export default I18nContext;
