import React, { useEffect, forwardRef } from 'react';
import $ from 'jquery';

const MaxHeightContainer = forwardRef(
  ({ children, id, offset, ...rest }, ref) => {
    const setHeight = () => {
      $(`#${id}`).height(
        $(window).height() - ($('#main-nav').height() + offset + 80),
      );
    };
    useEffect(() => {
      setHeight();

      $(window).bind('resize', setHeight);
      return () => {
        $(window).unbind('resize', setHeight);
      };
    }, []);

    return (
      <div
        style={{ height: '600px', overflowY: 'scroll' }}
        id={id}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  },
);

export default MaxHeightContainer;
