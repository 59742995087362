import has from 'lodash/has';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export const generateParams = (newParams, searchParams) => {
  const newSearchParams = {};
  searchParams.forEach((value, key) => {
    if (has(newParams, key) && isNil(newParams[key])) {
      return;
    }
    newSearchParams[key] = value;
  });
  return {
    ...newSearchParams,
    ...omitBy(newParams, isNil),
  };
};
