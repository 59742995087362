import React from 'react';
import { Alert, Card, Col, Row } from 'react-bootstrap';
import Moment from 'react-moment';
import Loader from 'components/ui/loader';
import { useI18n } from 'services/i18n/hook';
import { useApiDebug } from 'services/api_debug/hook';
import JsonViewer from 'components/ui/json_viewer';
import EmptyApiDebugImage from 'images/api_debug_empty.svg';
import EmptyState from 'components/ui/empty_state';
import ApiDebugSearch from 'components/api_debug/search';

const APIDebugCard = () => {
  const { t } = useI18n();
  const { runAt, errors, loading, results } = useApiDebug();
  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div>
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {t('components.api_debug.card.api_debug_title')}
              </h5>
              <small>
                {runAt && (
                  <>
                    {t('components.api_debug.card.last_call_at')}
                    <Moment from={runAt} format="DD/MM/YYYY HH:mm" />
                  </>
                )}
              </small>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="p-2 fs--1">
          <ApiDebugSearch />
          {loading ? (
            <Loader />
          ) : (
            <>
              {errors ? (
                <Alert variant="danger" className="mt-2">
                  {t('components.api_debug.card.error_occurred')}
                  <pre>{JSON.stringify(errors, null, 2)}</pre>
                </Alert>
              ) : (
                <>
                  {Object.keys(results).length > 0 ? (
                    <JsonViewer json={results} />
                  ) : (
                    <EmptyState
                      image={EmptyApiDebugImage}
                      text={t('components.api_debug.card.debug_an_api')}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default APIDebugCard;
