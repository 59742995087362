import React from 'react';

const EmptyState = ({ image, text }) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column p-5">
      <img className="w-25 mb-5" src={image} alt={text} />
      <p className="text-center">{text}</p>
    </div>
  );
};

export default EmptyState;
