import React, { useState, useEffect } from 'react';
import PaulApi from 'services/paul_api';
import RequestContext from 'services/request/context';
import { DEFAULT_ENV } from 'constants';
import { useI18n } from 'services/i18n/hook';
import { flatData } from 'services/flat_data';
import { useSearchParams } from 'react-router-dom';
import { generateParams } from 'services/url';
import { useApplication } from 'services/application/hook';
import get from 'lodash/get';

const RequestProvider = ({ children }) => {
  const { t } = useI18n();
  const { setGlobalRequest, globalRequest, globalRequestEnv, addinContext } =
    useApplication();
  const [requestId, setRequestId] = useState('');
  const [env, setEnv] = useState(DEFAULT_ENV);
  const [request, setRequest] = useState(null);
  const [flattenRequestData, setFlattenRequestData] = useState(null);
  const [availableWorkflowData, setAvailableWorkflowData] = useState([]);
  const [error, setRequestError] = useState(null);
  const [loading, setRequestLoading] = useState(false);
  const [loadedAt, setLoadedAt] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [usedCurrentUser, setUsedCurrentUser] = useState(null);
  const [requestDataFilter, setRequestDataFilter] = useState([]);

  const getRequest = (reqId, environment) => {
    const idToUse = reqId || requestId;
    const envToUse = environment || env;
    setRequestLoading(true);
    setRequestError(null);
    PaulApi.use(envToUse)
      .get(`/requests/${idToUse}`)
      .then((response) => {
        setGlobalRequest(response.data.data, envToUse);
        onRequest(response.data.data, envToUse);
        setLoadedAt(Date.now());
        if (!addinContext) {
          setSearchParams(
            generateParams({ env: envToUse, requestId: idToUse }, searchParams),
          );
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.status && error.status === 404) {
          setGlobalRequest(null, envToUse);
          setRequestError(
            t('services.request.provider.request_not_found', {
              requestId: idToUse,
              env,
            }),
          );
        } else {
          setRequestError(t('services.request.provider.an_error_occurred'));
        }
      })
      .finally(() => {
        setRequestLoading(false);
      });
  };

  const onRequest = (request, env) => {
    setRequest(request);
    setFlattenRequestData(flatData(request));
    if (request.workflow_id) {
      PaulApi.use(env)
        .get(`/workflows/list_variables/${request.workflow_id}`)
        .then((response) => {
          setAvailableWorkflowData(response.data.variables);
        });
    }
  };

  const setCurrentUser = (currentUserToUse) => {
    const currentUser = get(request, currentUserToUse);
    const newRequest = { ...request, current_user: currentUser };
    setRequest(newRequest);
    setFlattenRequestData(flatData(newRequest));
  };

  useEffect(() => {
    const envParam = globalRequestEnv
      ? globalRequestEnv
      : searchParams.get('env');
    const requestIdParam = globalRequest
      ? globalRequest.id
      : searchParams.get('requestId');
    if (envParam && requestIdParam) {
      setRequestId(requestIdParam);
      setEnv(envParam);
      getRequest(requestIdParam, envParam);
    }
  }, []);

  return (
    <RequestContext.Provider
      value={{
        request,
        flattenRequestData,
        availableWorkflowData,
        loading,
        getRequest,
        setRequestId,
        setEnv,
        requestId,
        env,
        error,
        loadedAt,
        setRequestDataFilter,
        requestDataFilter,
        clearRequest: () => {
          setRequestError(null);
          setRequestId('');
          setLoadedAt(null);
          setFlattenRequestData(null);
          setRequest(null);
          setSearchParams(
            generateParams({ env: null, requestId: null }, searchParams),
          );
          setGlobalRequest(null, null);
        },
        setCurrentUser,
        currentUser: usedCurrentUser,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
};

export default RequestProvider;
