import React from 'react';
import { ConditionList, Condition } from '@deecide/utils';
import { checkStep } from 'services/step';
import isEqual from 'lodash/isEqual';
import Popover from 'components/ui/popover';
import { useApplication } from 'services/application/hook';

export const createConditionList = (list) => {
  return new ConditionList(
    list.map((condition) => {
      return new Condition({
        ...condition,
      });
    }),
  );
};

const stepCheckStatus = [
  {
    label: 'OK',
    check: {
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
  },
  {
    label: 'NE',
    check: {
      isEditable: false,
      isVisible: true,
      isDisabled: false,
    },
  },
  {
    label: 'NE',
    check: {
      isEditable: true,
      isVisible: true,
      isDisabled: true,
    },
  },
  {
    label: 'NA',
    check: {
      isEditable: false,
      isVisible: true,
      isDisabled: true,
    },
  },
  {
    label: 'NV',
    check: {
      isEditable: false,
      isVisible: false,
      isDisabled: true,
    },
  },
  {
    label: 'NV',
    check: {
      isEditable: true,
      isVisible: false,
      isDisabled: true,
    },
  },
  {
    label: 'NV',
    check: {
      isEditable: false,
      isVisible: false,
      isDisabled: false,
    },
  },
  {
    label: 'NV',
    check: {
      isEditable: true,
      isVisible: false,
      isDisabled: true,
    },
  },
  {
    label: 'NV',
    check: {
      isEditable: true,
      isVisible: false,
      isDisabled: false,
    },
  },
];

const getCheckStatus = (check) => {
  const match = stepCheckStatus.find((checkStatus) => {
    return isEqual(checkStatus.check, check);
  });
  if (!match) {
    return 'NOK';
  }
  return match.label;
};

const Result = ({ parentCheck, parentStep, step, stepCheck, label }) => {
  const { getAttribute } = useApplication();
  const content = (
    <>
      <p>{getAttribute(parentStep, 'name')}</p>
      <pre>{JSON.stringify(parentCheck, null, 2)}</pre>
      <pre>{JSON.stringify(parentStep.conditions, null, 2)}</pre>
      <p>{getAttribute(step, 'name')}</p>
      <pre>{JSON.stringify(stepCheck, null, 2)}</pre>
      <pre>{JSON.stringify(step.conditions, null, 2)}</pre>
    </>
  );
  return (
    <Popover content={content} className="fs--xs">
      <span className={label === 'OK' ? 'text-success' : ''}>{label}</span>
    </Popover>
  );
};

const WorkflowStatusesStepStatus = ({
  parentStep,
  step,
  status,
  forRole = 'seller',
}) => {
  let result = null;
  const statusCheck = {
    isEditable:
      forRole === 'seller' ? status.seller_editable : status.provider_editable,
    isVisible: forRole === 'seller' ? status.seller_visible : true,
    isDisabled: false,
  };
  const parentCheck = checkStep(parentStep, status.slug);
  const subCheck = checkStep(step, status.slug);
  const parentCheckStatus = getCheckStatus(parentCheck);
  const subCheckStatus = getCheckStatus(subCheck);
  const statusCheckStatus = getCheckStatus(statusCheck);
  const bo = parentStep.backoffice_only || step.backoffice_only;
  if (bo && forRole === 'seller') {
    result = 'NV';
  } else {
    if (statusCheckStatus !== 'OK') {
      result = statusCheckStatus;
    } else if (parentCheckStatus !== 'OK') {
      result = parentCheckStatus;
    } else {
      result = subCheckStatus;
    }
  }
  return (
    <Result
      step={step}
      parentStep={parentStep}
      stepCheck={subCheck}
      parentCheck={parentCheck}
      label={result}
    />
  );
};

export default WorkflowStatusesStepStatus;
