import React from 'react';
import get from 'lodash/get';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import WorkflowTranslationsOpenButton from 'components/workflow/translations/open_button';
import './row.scss';
import { useWorkflow } from 'services/workflow/hook';

const WorkflowTranslationsRow = ({
  search,
  translationKey,
  usedTranslationKeys,
  missingTranslationKeys,
  allTranslations,
  onTranslationChange,
  toggleKey,
  selectedKeys = [],
}) => {
  const { locales } = useWorkflow();
  return (
    <tr
      key={translationKey}
      className={classNames('cursor-pointer', {
        'bg-soft-warning': !usedTranslationKeys.includes(translationKey),
        'bg-soft-danger': missingTranslationKeys.includes(translationKey),
      })}
    >
      <td>
        <Form.Check
          value={selectedKeys.includes(translationKey)}
          type="checkbox"
          onChange={() => toggleKey(translationKey)}
        />
      </td>
      <td className="translation-key">
        <Highlighter
          textToHighlight={translationKey}
          searchWords={[search]}
          autoEscape
          highlightClassName="bg-soft-info"
          onClick={() => {
            console.group(translationKey);
            console.log(allTranslations[translationKey]);
            console.groupEnd();
          }}
        />
        <WorkflowTranslationsOpenButton
          translationKey={translationKey}
          locales={locales}
          allTranslations={allTranslations}
          apply={onTranslationChange}
        />
      </td>
      {locales.map((locale) => {
        const translation = get(allTranslations[translationKey], locale, null);
        return (
          <td key={locale} className={!translation ? 'bg-soft-danger' : ''}>
            <div
              dangerouslySetInnerHTML={{
                __html: translation,
              }}
            />
          </td>
        );
      })}
    </tr>
  );
};

export default WorkflowTranslationsRow;
