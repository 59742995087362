import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { useLansky } from 'services/lansky/hook';
import 'react-json-pretty/themes/acai.css';
import JsonViewer from 'components/ui/json_viewer';
import LanskyIntegrityChecker from './integrity_checker';
import { DEVELOPMENT } from 'constants';

const LanskyResults = () => {
  const { t } = useI18n();
  const [key, setKey] = useState('results');
  const {
    results: { __debug, ...restResults },
    onlyLanskyInputs,
    toggleLanksyInputs,
  } = useLansky();
  const [collapse, setCollapse] = useState(false);
  const collapseAll = () => setCollapse((prev) => !prev);

  return (
    <div className="p-2">
      <Button onClick={collapseAll} size="sm" variant="secondary">
        {collapse
          ? t('components.lansky.results.open_all')
          : t('components.lansky.results.collapse_all')}
      </Button>
      {DEVELOPMENT && (
        <Button onClick={toggleLanksyInputs} size="sm" variant="secondary">
          {onlyLanskyInputs
            ? t('components.lansky.results.show_only_lansky_inputs')
            : t('components.lansky.results.show_all_data')}
        </Button>
      )}
      {false && <LanskyIntegrityChecker />}
      <Tabs
        defaultActiveKey="profile"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab
          eventKey="results"
          title={t('components.lansky.results.results')}
          className="fs-1"
        >
          <JsonViewer json={restResults} collapse={collapse} />
        </Tab>
        <Tab eventKey="inputs" title={t('components.lansky.results.inputs')}>
          <JsonViewer json={__debug} collapse={collapse} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default LanskyResults;
