import React from 'react';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import { useRequest } from 'services/request/hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EnvironmentSelectField from 'components/environment/select_field';
import { ENV_DOMAINS } from 'constants';

const RequestSearchForm = () => {
  const { t } = useI18n();
  const {
    loading,
    getRequest,
    env,
    requestId,
    setRequestId,
    setEnv,
    clearRequest,
  } = useRequest();
  const disableSearch = loading || !requestId || requestId.length === 0 || !env;
  const clear = () => {
    clearRequest();
  };
  return (
    <InputGroup>
      {requestId?.length > 0 && (
        <Button variant="danger" size="sm" onClick={clear}>
          <FontAwesomeIcon icon={['fa', 'trash']} />
        </Button>
      )}
      <Form.Control
        size="sm"
        placeholder={t('components.request.search.form.request_id_placeholder')}
        disabled={loading}
        value={requestId}
        style={{
          width: '220px',
        }}
        onChange={(ev) => {
          const value = ev.target.value;
          if (value.includes('/requests/')) {
            const split = value.split('/requests/');
            const domain = split[0];
            const requestPath = split[1];
            const envDomainIndex = ENV_DOMAINS.findIndex((envDomain) =>
              domain.includes(envDomain.domain),
            );
            const id = requestPath.split('/')[0];
            setRequestId(id);
            if (envDomainIndex > -1) {
              const urlEnv = ENV_DOMAINS[envDomainIndex].env;
              setEnv(urlEnv);
              getRequest(id, urlEnv);
            }
            return;
          }
          setRequestId(ev.target.value);
        }}
      />
      <EnvironmentSelectField env={env} setEnv={setEnv} loading={loading} />
      <Button
        variant="primary"
        size="sm"
        disabled={disableSearch}
        onClick={() => {
          getRequest();
        }}
      >
        <FontAwesomeIcon icon={['fa', 'magnifying-glass']} />
      </Button>
    </InputGroup>
  );
};

export default RequestSearchForm;
