import React from 'react';
import { useWorkflow } from 'services/workflow/hook';
import { Card, Col, Row } from 'react-bootstrap';
import WorkflowStatusesSearch from 'components/workflow/statuses/search';
import { useI18n } from 'services/i18n/hook';
import WorkflowApiDocumentationSchema from 'components/workflow/api_documentation/schema';

const WorkflowApiDocumentation = () => {
  const { selectedWorkflow } = useWorkflow();
  const { t } = useI18n();
  return (
    <Card>
      <Card.Header className="bg-light">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <div>
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                {t('components.workflow.api_documentation.index.generate_api')}
              </h5>
            </div>
          </Col>
          <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
            <div id="orders-actions">
              <WorkflowStatusesSearch filter={false} />
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        {selectedWorkflow && <WorkflowApiDocumentationSchema />}
      </Card.Body>
    </Card>
  );
};

export default WorkflowApiDocumentation;
