import React from 'react';

const APIDebugContext = React.createContext({
  callApi: () => {},
  loading: false,
  errors: null,
  results: {},
  setApiPath: () => {},
  apiPath: null,
  runAt: null,
});

export default APIDebugContext;
