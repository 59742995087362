import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { listFieldsOfWorkflow } from '@deecide/utils';
import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import { getStepsAvailableData } from 'services/schema/schema';
import { useWorkflow } from 'services/workflow/hook';
import { useI18n } from 'services/i18n/hook';
import { useSession } from 'services/sessions/hook';
import Button from 'components/ui/button';
import StatsCard from 'components/ui/stats_card';

const WorkflowApiDocumentationSchema = () => {
  const { selectedWorkflow, selectedEnv } = useWorkflow();
  const { currentSessions } = useSession();
  const [data, setData] = useState({});
  const [keys, setKeys] = useState([]);
  const [keysCount, setKeysCount] = useState(0);
  const [fields, setFields] = useState({});

  const flatDataForDoc = (param, prefix = '') => {
    const flattened = {};
    Object.keys(param).map((key) => {
      const v = param[key];
      if (isObject(v)) {
        if (has(v, 'TYPE' || has(v, 'FORMAT') || has(v, 'ENUM'))) {
          flattened[`${prefix}${key}`] = v;
        } else {
          merge(flattened, flatDataForDoc(v, `${prefix}${key}.`));
        }
      } else if (isArray(v)) {
        const array = v.map((val, index) => {
          return flatDataForDoc(val, `${prefix}${key}[${index}].`);
        });
        array.forEach((item) => {
          merge(flattened, item);
        });
      } else {
        flattened[`${prefix}${key}`] = v;
      }
    });
    return { ...flattened };
  };

  const getAvailableVariables = () => {
    getStepsAvailableData(
      selectedWorkflow.workflow.steps,
      {},
      selectedEnv,
      currentSessions,
      {
        constants: selectedWorkflow.workflow.constants,
      },
    ).then(async (parse) => {
      console.log('parse', parse);
      const flatParse = flatDataForDoc(parse, '');
      setData(flatParse);
      setKeys(sortBy(Object.keys(flatParse)));
      setKeysCount(Object.keys(flatParse).length);
    });
    const f = listFieldsOfWorkflow(selectedWorkflow.workflow);
    setFields(f);
    console.log('fields', f);
  };

  useEffect(() => {
    getAvailableVariables();
  }, []);

  const { t } = useI18n();

  return (
    <div className="p-2">
      <div className="d-flex justify-content-between align-items-center">
        <StatsCard
          title={t('components.workflow.api_documentation.schema.keys_count')}
        >
          {keysCount}
        </StatsCard>
        <Button onClick={getAvailableVariables}>Refresh</Button>
      </div>

      <Table size="sm" hover striped bordered className="fs--1">
        <thead>
          <tr>
            <th>{t('components.workflow.api_documentation.schema.keys')}</th>
            <th>{t('components.workflow.api_documentation.schema.type')}</th>
            <th>{t('components.workflow.api_documentation.schema.format')}</th>
            <th>{t('components.workflow.api_documentation.schema.enums')}</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key, index) => (
            <tr key={index}>
              <td>{key}</td>
              <td>{data[key].TYPE}</td>
              <td>{data[key].FORMAT}</td>
              <td>
                <code className="fs--1">{data[key].ENUMS?.join(', ')}</code>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default WorkflowApiDocumentationSchema;
