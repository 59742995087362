import React, { useEffect, useState } from 'react';
import { useRequest } from 'services/request/hook';
import { getWorkflow } from 'requests/workflow';
import sortBy from 'lodash/sortBy';
import Table from 'react-bootstrap/Table';
import { validateWorkflow } from '@deecide/utils';
import RequestNavigationRow from 'components/request/navigation/row';
import { useI18n } from 'services/i18n/hook';
import Loader from 'components/ui/loader';
import Button from 'components/ui/button';
import copy from 'clipboard-copy';

const RequestNavigationMenu = () => {
  const { t } = useI18n();
  const { request, env } = useRequest();
  const [workflow, setWorkflow] = useState({ steps: [] });
  const [loading, setLoading] = useState(false);
  const [stepsMetadata, setStepsMetadata] = useState({});
  const [stepsToHighlight, setStepsToHighlight] = useState([]);
  useEffect(() => {
    setLoading(true);
    getWorkflow(request.workflow_id, env)
      .then((response) => {
        setWorkflow(response.data.workflow);
        setStepsMetadata(validateWorkflow(response.data.workflow, request));
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-end m-2">
            <Button
              size="sm"
              onClick={() => {
                copy(JSON.stringify(workflow));
              }}
            >
              Copy workflow
            </Button>
            <Button
              size="sm"
              onClick={() => {
                copy(JSON.stringify(request));
              }}
            >
              Copy data
            </Button>
          </div>
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>{t('components.request.navigation.menu.step')}</th>
                <th className="text-center">
                  {t('components.request.navigation.menu.errors')}
                </th>
                <th className="text-center">
                  {t('components.request.navigation.menu.visible')}
                </th>
                <th className="text-center">
                  {t('components.request.navigation.menu.editable')}
                </th>
                <th className="text-center">
                  {t('components.request.navigation.menu.disabled')}
                </th>
              </tr>
            </thead>
            {sortBy(workflow.steps, 'position').map(
              (businessStep, businessStepIndex) => {
                const parentNumber = businessStepIndex + 1;
                return (
                  <React.Fragment key={businessStep.key}>
                    <RequestNavigationRow
                      number={`${parentNumber}.`}
                      step={businessStep}
                      parentStep={null}
                      stepsMetadata={stepsMetadata}
                      highlighted={
                        stepsToHighlight.findIndex(
                          (step) => step.id === businessStep.id,
                        ) > -1
                      }
                      setStepsToHighlight={setStepsToHighlight}
                    />
                    {sortBy(businessStep.steps, 'position').map(
                      (subStep, substepIndex) => (
                        <RequestNavigationRow
                          number={`${parentNumber}.${substepIndex + 1}.`}
                          step={subStep}
                          parentStep={businessStep}
                          stepsMetadata={stepsMetadata}
                          highlighted={
                            stepsToHighlight.findIndex(
                              (step) => step.id === subStep.id,
                            ) > -1
                          }
                          setStepsToHighlight={setStepsToHighlight}
                        />
                      ),
                    )}
                  </React.Fragment>
                );
              },
            )}
          </Table>
        </>
      )}
    </div>
  );
};

export default RequestNavigationMenu;
