import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'quill-paste-smart';
import 'react-quill/dist/quill.snow.css';
import { useRequest } from 'services/request/hook';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';
import './form.css';
import RenderLiquid from 'components/ui/render_liquid';

const WorkflowEmailBuilderForm = ({ email, locale }) => {
  const { t } = useI18n();
  const [activeTab, setActiveTab] = useState('build');
  const [subject, setSubject] = useState('');
  const [recipient, setRecipient] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    if (!email || !locale) {
      setSubject('');
      setBody('');
      return;
    }
    setBody(email.body_translations[locale]);
    setSubject(email.subject_translations[locale]);
    setRecipient(email.recipient_translations[locale]);
  }, [email, locale]);
  const { request } = useRequest();
  try {
  } catch (e) {
    console.error(e);
  }
  return (
    <>
      <Tabs
        defaultActiveKey="build"
        activeKey={activeTab}
        onSelect={(k) => setActiveTab(k)}
        className="mb-3 build-email"
      >
        <Tab
          eventKey="build"
          title={t('components.workflow.email_builder.form.write')}
          className="fs-1"
        >
          <div className="p-2">
            <Form.Group className="mb-3">
              <Form.Label htmlFor="recipient">
                {t('components.workflow.email_builder.form.recipient')}
              </Form.Label>
              <Form.Control
                id="recipient"
                size="sm"
                placeholder={t(
                  'components.workflow.email_builder.form.recipient',
                )}
                value={recipient}
                onChange={(ev) => {
                  setRecipient(ev.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="subject">
                {t('components.workflow.email_builder.form.email_subject')}
              </Form.Label>
              <Form.Control
                id="subject"
                size="sm"
                placeholder={t(
                  'components.workflow.email_builder.form.email_subject',
                )}
                value={subject}
                onChange={(ev) => {
                  setSubject(ev.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="body">
                {t('components.workflow.email_builder.form.email_body')}
              </Form.Label>
              <ReactQuill
                theme="snow"
                value={body}
                onChange={(newTemplate) => {
                  setBody(newTemplate);
                }}
              />
            </Form.Group>
          </div>
        </Tab>
        {request && (
          <Tab
            eventKey="result"
            title={t('components.workflow.email_builder.form.preview')}
            className="fs--1"
            key={activeTab}
          >
            <div className="p-2 bg-light">
              <div className="mb-3">
                <p className="mb-1 fw-bold">
                  {t('components.workflow.email_builder.form.to')}
                </p>
                <div className="p-1 bg-white">
                  <RenderLiquid
                    template={recipient}
                    data={request}
                    className="result-subject"
                  />
                </div>
              </div>
              <div className="mb-3">
                <p className="mb-1 fw-bold">
                  {t('components.workflow.email_builder.form.subject')}
                </p>
                <div className="p-1 bg-white">
                  <RenderLiquid
                    template={subject}
                    data={request}
                    className="result-subject"
                  />
                </div>
              </div>
              <p className="mb-1 fw-bold">
                {t('components.workflow.email_builder.form.body')}
              </p>
              <div className="p-1 bg-white">
                <RenderLiquid template={body} data={request} />
              </div>
            </div>
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default WorkflowEmailBuilderForm;
