import Api from 'services/api';

export const getBrands = (env, expand = []) => {
  return new Promise((resolve, reject) => {
    Api.use(env).get('/brands', { params: { expand } })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
