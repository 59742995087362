import React from 'react';
import WorkflowProvider from 'services/workflow/provider';
import AddinWatsonBuilder from 'components/addin/watson/builder';

const AddinWordScene = () => {
  return (
    <WorkflowProvider>
      <AddinWatsonBuilder />
    </WorkflowProvider>
  );
};

export default AddinWordScene;
