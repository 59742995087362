import React from 'react';
import Field from 'components/ui/form/field';
import { finalForm } from 'components/ui/form/final_form';
import FormInput from 'components/ui/form/input';
import { otp, required } from 'components/ui/form/validations';
import { Button, Form, Alert } from 'react-bootstrap';
import { useI18n } from 'services/i18n/hook';

const SessionSignInOTPForm = ({ invalid, submitting, handleSubmit }) => {
  const { t } = useI18n();
  return (
    <form onSubmit={handleSubmit}>
      <Alert className="text-center p-2" color="info">
        {t('components.session.sign_in.otp_form.otp_explanation')}
      </Alert>
      <Field name="email" type="hidden" component="input" />
      <Field name="password" type="hidden" component="input" />
      <Field name="env" type="hidden" component="input" />
      <Field
        name="otp"
        component={FormInput}
        type="text"
        autoFocus="true"
        validate={[required, otp]}
        label={t('components.session.sign_in.otp_form.otp_label')}
        placeholder={t('components.session.sign_in.otp_form.otp_placeholder')}
      />
      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={invalid || submitting}
          onClick={handleSubmit}
        >
          {t('components.session.sign_in.otp_form.send_code')}
        </Button>
      </Form.Group>
    </form>
  );
};
export default finalForm(SessionSignInOTPForm);
