import React from 'react';
import compact from 'lodash/compact';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import Logo from 'components/ui/logo';
import { Link, NavLink, useSearchParams } from 'react-router-dom';
import NavigationMenuDropdown from 'components/navigation/menu_dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavigationUserMenu from 'components/navigation/user_menu';
import { DEVELOPMENT } from 'constants';
import { useApplication } from 'services/application/hook';

const NavigationTop = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const { inIframe } = useApplication();
  const menu = [
    {
      path: '/lansky',
      label: 'Lansky',
    },
    {
      path: '/watson',
      label: 'Watson',
    },
    {
      path: '/workflow',
      label: 'Workflow',
      subs: [
        {
          path: '/workflow/statuses',
          label: 'Statuses',
          icon: 'table',
        },
        DEVELOPMENT && {
          path: '/workflow/flow',
          label: 'Flow',
          icon: 'sitemap',
        },
        {
          path: '/workflow/email',
          label: 'Email',
          icon: 'envelope',
        },
        {
          path: '/workflow/translations',
          label: 'Translations',
          icon: 'language',
        },
        {
          path: '/workflow/api_documentation',
          label: 'Api documentation',
          icon: 'book',
        },
        {
          path: '/workflow/html_generator',
          label: 'HTML Generator',
          icon: 'book',
        },
        {
          path: '/workflow/tester',
          label: 'Tester',
          icon: 'book',
        },
      ],
    },
    {
      path: '/request',
      label: 'Request',
      subs: [
        {
          path: '/request/navigation',
          label: 'Navigation',
          icon: 'compass',
        },
      ],
    },
    {
      path: '/embed_builder',
      label: 'Embed builder',
      icon: 'window-restore',
    },
    {
      path: '/api_debug',
      label: 'Api Debug',
    },
  ];

  return (
    <Navbar className="navbar-glass fs--1 navbar-top sticky-kit" id="main-nav">
      <Logo at="navbar-top" width={150} id="topLogo" />
      {!inIframe && (
        <Nav navbar as="ul">
          {menu.map((item, index) => {
            if (item.subs) {
              return (
                <NavigationMenuDropdown title={item.label} key={index}>
                  {compact(item.subs).map((sub, subIndex) => (
                    <Dropdown.Item
                      as={Link}
                      key={subIndex}
                      to={`${sub.path}/?${searchParams.toString()}`}
                    >
                      <FontAwesomeIcon
                        icon={['fa', sub.icon]}
                        className="me-2"
                      />
                      {sub.label}
                    </Dropdown.Item>
                  ))}
                </NavigationMenuDropdown>
              );
            }
            return (
              <Nav.Item key={index}>
                <Nav.Link
                  as={NavLink}
                  to={`${item.path}/?${searchParams.toString()}`}
                >
                  {item.label}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      )}
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
      >
        <NavigationUserMenu />
      </Nav>
    </Navbar>
  );
};

export default NavigationTop;
