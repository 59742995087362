import React from 'react';
import classNames from 'classnames';

const StatsCard = ({ title, children, className, ...rest }) => {
  return (
    <div
      className={classNames('p-2 pe-4 text-start cursor-pointer', className)}
      {...rest}
    >
      <h6 className="text-800 fs--2 text-nowrap">{title}</h6>
      <h5 className="text-800">{children}</h5>
    </div>
  );
};

export default StatsCard;
