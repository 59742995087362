import React from 'react';

const WatsonContext = React.createContext({
  runWatson: () => {},
  loading: false,
  errors: null,
  results: {},
  setEnv: () => {},
  env: null,
  runAt: null,
});

export default WatsonContext;
