import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CheckIcon = ({ check, className }) => {
  return (
    <span className={className}>
      {check ? (
        <FontAwesomeIcon
          icon={['fa', 'fa-circle-check']}
          className="text-success"
        />
      ) : (
        <FontAwesomeIcon
          icon={['fa', 'fa-circle-xmark']}
          className="text-danger"
        />
      )}
    </span>
  );
};

export default CheckIcon;
