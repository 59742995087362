import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMagnifyingGlass,
  faCalculator,
  faFilter,
  faTrash,
  faFileExcel,
  faFileWord,
  faPrint,
  faCircleExclamation,
  faPaperPlane,
  faTable,
  faEnvelope,
  faLanguage,
  faLock,
  faLockOpen,
  faCompass,
  faSitemap,
  faCircleCheck,
  faCircleXmark,
  faBook,
  faImage,
  faParagraph,
  faDollarSign,
  faCalendar,
  faListCheck,
  faCheckToSlot,
  faPenToSquare,
  faWindowRestore,
  faSignature,
} from '@fortawesome/free-solid-svg-icons';

import React, { useEffect } from 'react';

const WithIcons = ({ children }) => {
  useEffect(
    () =>
      library.add(
        faMagnifyingGlass,
        faCalculator,
        faFilter,
        faTrash,
        faFileExcel,
        faPrint,
        faFileWord,
        faCircleExclamation,
        faPaperPlane,
        faTable,
        faEnvelope,
        faLanguage,
        faLock,
        faLockOpen,
        faSitemap,
        faCompass,
        faCircleCheck,
        faCircleXmark,
        faBook,
        faImage,
        faParagraph,
        faDollarSign,
        faCalendar,
        faListCheck,
        faCheckToSlot,
        faPenToSquare,
        faWindowRestore,
        faSignature,
      ),
    [],
  );
  return children;
};

export default WithIcons;
