import React from 'react';
import ReactJson from 'react-json-view';

const JsonViewer = ({ json, collapse }) => {
  return (
    <ReactJson
      src={json}
      indentWidth={2}
      style={{ fontSize: '12px' }}
      enableClipboard={false}
      collapsed={collapse}
    />
  );
};

export default JsonViewer;
