import React, { useEffect, useState } from 'react';
import WorkflowContext from 'services/workflow/context';
import { getWorkflow, getWorkflows, getAvailableData } from 'requests/workflow';
import orderBy from 'lodash/orderBy';
import { useSearchParams } from 'react-router-dom';
import { generateParams } from 'services/url';
import { DEFAULT_ENV } from 'constants';
import sortBy from 'lodash/sortBy';
import flattenDeep from 'lodash/flattenDeep';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useApplication } from 'services/application/hook';
import { useOffice } from 'services/office/provider';

export const filterOnlyLinkedDocumentTemplatesIds = (selectedWorkflow) => {
  if (!selectedWorkflow || !selectedWorkflow.workflow) return [];
  const templateIds = selectedWorkflow.workflow.steps.map((businessStep) => {
    return businessStep.steps.map((subStep) => {
      return subStep.blocks.map((block) => {
        if (block.kind === 'operation' || block.kind === 'next_step') {
          return block?.operations?.map(
            (operation) => operation.document_template_ids,
          );
        }
      });
    });
  });
  return uniq(compact(flattenDeep(templateIds)));
};

const WorkflowProvider = ({ children }) => {
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [selectedEnv, setSelectedEnv] = useState(DEFAULT_ENV);
  const [loadingWorkflows, setLoadingWorkflows] = useState(false);
  const [loadingWorkflow, setLoadingWorkflow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [linkedDocumentTemplates, setLinkedDocumentTemplates] = useState([]);
  const { globalRequest, globalRequestEnv } = useApplication();
  const { inAddin } = useOffice();
  const init = (env = selectedEnv) => {
    setSelectedWorkflow(null);
    setLoadingWorkflows(true);
    getWorkflows(env)
      .then((response) => {
        setWorkflows(() =>
          orderBy(
            response.data.workflows.filter(
              (workflow) => workflow.status !== 'disabled',
            ),
            'updated_at',
            'desc',
          ),
        );
        setLoadingWorkflows(false);
      })
      .catch((e) => {
        setLoadingWorkflows(false);
        console.log(e);
      });
  };

  const loadWorkflow = (workflowId, env) => {
    setLoadingWorkflow(true);
    return new Promise((resolve, reject) => {
      getWorkflow(workflowId, env || selectedEnv)
        .then((response) => {
          !inAddin &&
            setSearchParams(
              generateParams(
                { workflowId, env: env || selectedEnv },
                searchParams,
              ),
            );
          setSelectedWorkflow(response.data);
          const linkedDocTemplateIds = filterOnlyLinkedDocumentTemplatesIds(
            response.data,
          );
          setLinkedDocumentTemplates(() => {
            return linkedDocTemplateIds.map((id) =>
              response.data.document_templates.find((doc) => doc.id === id),
            );
          });
          setSelectedEnv(env || selectedEnv);
          setLoadingWorkflow(false);
          resolve(response.data);
        })
        .catch((e) => {
          setLoadingWorkflow(false);
          console.error(e);
        });
    });
  };

  const getWorkflowAvailableData = async (workflowId, env) => {
    const response = await getAvailableData(workflowId, env || selectedEnv);
    return response.data.variables;
  };

  const refresh = () => {
    if (selectedEnv && selectedWorkflow) {
      loadWorkflow(selectedWorkflow.workflow.id, selectedEnv);
    }
  };

  useEffect(() => {
    const workflowIdParam = globalRequest
      ? globalRequest.workflow_id
      : searchParams.get('workflowId');
    const envParam = globalRequestEnv
      ? globalRequestEnv
      : searchParams.get('env') || DEFAULT_ENV;
    if (envParam) {
      setSelectedEnv(envParam);
    }
    init(envParam);
    if (workflowIdParam && !selectedWorkflow) {
      loadWorkflow(workflowIdParam, envParam);
    }
  }, []);

  return (
    <WorkflowContext.Provider
      value={{
        workflows,
        selectedWorkflow,
        selectedEnv,
        setSelectedEnv: (env) => {
          setSelectedEnv(env);
          init(env);
        },
        setSelectedWorkflow: loadWorkflow,
        loadingWorkflows,
        loadingWorkflow,
        loading: loadingWorkflows || loadingWorkflow,
        refresh,
        locales: sortBy(selectedWorkflow?.product.locales || []),
        linkedDocumentTemplates,
        getWorkflowAvailableData,
      }}
    >
      {children}
    </WorkflowContext.Provider>
  );
};

export default WorkflowProvider;
