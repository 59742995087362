import get from 'lodash/get';
import has from 'lodash/has';
import isObject from 'lodash/isObject';

export const getEnums = (list, data) => {
  if (isObject(list) && has(list, 'from')) {
    return {
      ENUMS: get(data, list.from),
    };
  }
  return { ENUMS: Array.isArray(list) ? list.map(({ value }) => value) : list };
};
