import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useWorkflow } from 'services/workflow/hook';
import { useI18n } from 'services/i18n/hook';
import WorkflowSelector from 'components/workflow/selector';

const WorkflowStatusesSearch = ({
  filter = false,
  setShowSeller,
  showSeller,
  setShowProvider,
  showProvider,
}) => {
  const { t } = useI18n();
  const { selectedWorkflow } = useWorkflow();
  return (
    <WorkflowSelector>
      {selectedWorkflow && selectedWorkflow.workflow && filter && (
        <Dropdown autoClose="outside">
          <Dropdown.Toggle variant="secondary" size="sm">
            <FontAwesomeIcon icon={['fa', 'filter']} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-2 text-900">
            <Form.Check
              size="sm"
              type="switch"
              className="mb-0"
              onChange={(ev) => {
                setShowSeller(ev.target.checked === true);
              }}
              checked={showSeller}
              id="show_seller"
              label={t('components.workflow.statuses.search.show_seller')}
            />
            <Form.Check
              size="sm"
              type="switch"
              className="mb-0"
              onChange={(ev) => {
                setShowProvider(ev.target.checked === true);
              }}
              checked={showProvider}
              id="show_provider"
              label={t('components.workflow.statuses.search.show_provider')}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </WorkflowSelector>
  );
};

export default WorkflowStatusesSearch;
