import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RequestDataCard from 'components/request/data/card';
import RequestProvider from 'services/request/provider';
import WorkflowEmailBuilderCard from 'components/workflow/email_builder/card';

const ScenesWorkflowEmailBuilder = () => {
  return (
    <RequestProvider>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <WorkflowEmailBuilderCard />
        </Col>
        <Col md={5}>
          <RequestDataCard />
        </Col>
      </Row>
    </RequestProvider>
  );
};

export default ScenesWorkflowEmailBuilder;
