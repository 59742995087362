import React from 'react';
import { Handle, Position } from 'reactflow';
import { Badge } from 'react-bootstrap';
import { useApplication } from 'services/application/hook';
import classNames from 'classnames';

const WorkflowFLowNode = (props) => {
  const { data, selected } = props;
  const { getAttribute } = useApplication();
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div
        className={classNames(
          `border border-${
            data.status.color === 'light' ? 'secondary' : data.status.color
          }  p-2 text-center`,
          {
            'border-2': selected,
          },
        )}
      >
        <p className="mb-0">{getAttribute(data.status, 'seller_name')}</p>
        <Badge
          bg={data.status.color}
          className={data.status.color === 'light' ? 'text-900' : ''}
        >
          {data.status.slug}
        </Badge>
      </div>
      <Handle type="source" position={Position.Right} />
    </>
  );
};

export default WorkflowFLowNode;
