import React from 'react';

const RequestContext = React.createContext({
  loading: false,
  getRequest: (requestId, env) => {},
  request: null,
  flattenRequestData: null,
  setRequestId: () => {},
  setEnv: () => {},
  requestId: null,
  env: null,
  error: null,
  loadedAt: null,
});

export default RequestContext;
