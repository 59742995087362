import React from 'react';
import { RouterProvider } from 'react-router-dom';
import publicRouter from 'app/main/router';
import authenticatedRouter from 'app/authenticated/router';
import { useSession } from 'services/sessions/hook';
import { useApplication } from 'services/application/hook';
import addinRouter from 'app/addin/router';

const AppMainContainer = () => {
  const { isSignedIn } = useSession();
  const { addinContext } = useApplication();

  return (
    <RouterProvider
      router={
        isSignedIn
          ? addinContext
            ? addinRouter
            : authenticatedRouter
          : publicRouter
      }
    />
  );
};

export default AppMainContainer;
