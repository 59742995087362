import React from 'react';
import { Outlet } from 'react-router-dom';
import OfficeProvider from 'services/office/provider';

const AddinRoot = () => {
  return (
    <OfficeProvider>
      <div className="container-fluid">
        <div className="content p-2">
          <Outlet />
        </div>
      </div>
    </OfficeProvider>
  );
};

export default AddinRoot;
