import Api from 'services/api';

export const getSession = (env) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .get('sessions')
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSession = ({ env, ...attributes }, captcha) => {
  return new Promise((resolve, reject) => {
    Api.use(env)
      .post('sessions', { session: attributes, captcha })
      .then((response) => {
        resolve({ response });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
