export const Bool = {
  TYPE: 'boolean',
};

export const Str = {
  TYPE: 'string',
};

export const Numb = {
  TYPE: 'number',
};

export const Arr = {
  TYPE: 'array',
};

export const Date = {
  TYPE: 'string',
  FORMAT: 'date',
};
