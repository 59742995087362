import React, { useRef } from 'react';
import FileContext from 'services/file/context';

const FileProvider = ({ children }) => {
  const fileInputRef = useRef(null);
  const resolveRef = useRef(null);

  const handleFileSelect = (e) => {
    watchFile(e);
    const files = e.target.files;
    const file = files[0];
    resolveRef.current && resolveRef.current(file);
    resolveRef.current = null;
  };

  const watchFile = async (fileHandle, callback) => {
    let lastUpdate = null;
    setInterval(async () => {
      let file = await fileHandle.getFile();
      if (!lastUpdate || lastUpdate < file.lastModified) {
        lastUpdate = file.lastModified;
        return callback(file);
      }
    }, 2000);
  };

  const openFile = async (onFileChange) => {
    return new Promise(async (resolve, reject) => {
      try {
        const [fileHandle] = await window.showOpenFilePicker();
        const file = await fileHandle.getFile();
        if (onFileChange) {
          watchFile(fileHandle, onFileChange);
        }
        resolve(file);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  return (
    <FileContext.Provider
      value={{
        selectFile: openFile,
      }}
    >
      <input
        type="file"
        className="d-none"
        ref={fileInputRef}
        onChange={handleFileSelect}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
      {children}
    </FileContext.Provider>
  );
};

export default FileProvider;
