import React, { useId, useState } from 'react';
import Button from 'components/ui/button';
import { SESSION_ENVS } from 'constants';
import { Modal } from 'react-bootstrap';
import SessionSignInContainer from 'components/session/sign_in/container';
import { useI18n } from 'services/i18n/hook';

const SessionSignInButton = ({ env }) => {
  const { t } = useI18n();
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Modal onHide={() => setIsModalOpen((prev) => !prev)} show={isModalOpen}>
        <Modal.Header>
          {t('components.session.sign_in.button.connect_to', {
            env: SESSION_ENVS[env],
          })}
        </Modal.Header>
        <Modal.Body>
          <SessionSignInContainer env={env} />
        </Modal.Body>
      </Modal>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={(ev) => {
          ev.preventDefault();
          setIsModalOpen(true);
        }}
      >
        {t('components.session.sign_in.button.connect_to', {
          env: SESSION_ENVS[env],
        })}
      </Button>
    </>
  );
};

export default SessionSignInButton;
