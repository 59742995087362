import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import './data_path_actions.scss';
import VariableUsagePathActionButton from 'components/workflow/variable_usage/path_action_button';

const LanskyDataPathActions = ({ path }) => {
  return (
    <ButtonGroup size="sm" className="data-action-group">
      <VariableUsagePathActionButton path={path} />
    </ButtonGroup>
  );
};

export default LanskyDataPathActions;
