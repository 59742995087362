import React, { useState } from 'react';
import Button from 'components/ui/button';
import { useI18n } from 'services/i18n/hook';
import { useWatson } from 'services/watson/hook';
import { Modal } from 'react-bootstrap';
import WatsonWarningTable from 'components/watson/warning_table';

const WarningButton = () => {
  const { generationWarnings } = useWatson();
  const [showWarnings, setShowWarnings] = useState(false);
  const toggle = () => setShowWarnings((prevShow) => !prevShow);
  const { t } = useI18n();

  return (
    <>
      <Button
        variant="warning"
        size="sm"
        onClick={toggle}
        disabled={!generationWarnings.length}
        icon={['fa', 'circle-exclamation']}
      >
        {t('components.watson.selector.form.warnings', {
          count: generationWarnings.length,
        })}
      </Button>
      <Modal show={showWarnings} onHide={toggle} size="xl">
        <Modal.Header>
          {t('components.watson.selector.form.warnings', {
            count: generationWarnings.length,
          })}
        </Modal.Header>
        <Modal.Body>
          <WatsonWarningTable />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WarningButton;
