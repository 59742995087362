import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import isArray from 'lodash/isArray';

export const flatData = (param, prefix = '') => {
  const flattened = {};
  Object.keys(param).map((key) => {
    const v = param[key];
    if (isObject(v)) {
      merge(flattened, flatData(v, `${prefix}${key}.`));
    } else if (isArray(v)) {
      const array = v.map((val, index) => {
        return flatData(val, `${prefix}${key}[${index}].`);
      });
      array.forEach((item) => {
        merge(flattened, item);
      });
    } else {
      flattened[`${prefix}${key}`] = v;
    }
  });
  return { ...flattened };
};
