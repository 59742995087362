import React from 'react';
import { RouterProvider } from 'react-router-dom';
import publicRouter from 'app/main/router';
import { useSession } from 'services/sessions/hook';
import addinRouter from 'app/addin/router';
import './container.scss';

const AddinContainer = () => {
  const { isSignedIn } = useSession();

  return <RouterProvider router={isSignedIn ? addinRouter : publicRouter} />;
};

export default AddinContainer;
