import isEmpty from 'lodash/isEmpty';

export const required = (value) => {
  return isEmpty(value)
    ? { t: 'components.ui.form.validations.required' }
    : null;
};

export const email = (value) => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? { t: 'components.ui.form.validations.invalid_email' }
    : undefined;
};

export const otp = (value) => {
  return value && !/^[\d]{6}$/g.test(value)
    ? { t: 'components.ui.form.validations.invalid_otp' }
    : undefined;
};
