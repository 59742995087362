import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import set from 'lodash/set';
import flattenDeep from 'lodash/flattenDeep';
import { flatData } from './flat_data';

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
};

export const getTestInputs = (file) => {
  // read file from file input
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = 'test_inputs';
      const sheet = workbook.Sheets[sheetName];
      const content = XLSX.utils.sheet_to_json(sheet, { defval: null });
      const newObjects = content.map((row) => {
        const entries = Object.entries(row);
        const obj = {};
        entries.forEach(([key, value]) => {
          set(obj, key, value);
        });
        return obj;
      });
      return resolve(newObjects);
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const getOutputData = (file) => {
  // read file from file input
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = 'test_outputs';
      const sheet = workbook.Sheets[sheetName];
      const content = XLSX.utils.sheet_to_json(sheet, {
        raw: true,
        header: 1,
        defval: '',
      });
      return resolve(flattenDeep(content));
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const generateTestResults = (testCases, outputData) => {
  // todo refactor the following

  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: 'Test Results',
    CreatedDate: new Date(),
  };
  const inputSheet = XLSX.utils.json_to_sheet(
    testCases.map((testCase) => flatData(testCase.data.input)),
  );

  const outputSheet = XLSX.utils.json_to_sheet(
    testCases.map((testCase) => {
      const output = {};
      outputData.forEach(
        (data) => (output[data] = get(testCase.data.output, data)),
      );
      return output;
    }),
  );
  wb.SheetNames.push('test_inputs');
  wb.SheetNames.push('test_outputs');
  wb.Sheets['test_inputs'] = inputSheet;
  wb.Sheets['test_outputs'] = outputSheet;
  const workbookOut = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  saveAs(
    new Blob([s2ab(workbookOut)], { type: 'application/octet-steam' }),
    'test_results.xlsx',
  );
};

export const generateXLSX = (data, workflow, locales) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: `${workflow.name} - Translations`,
    CreatedDate: new Date(),
  };

  const arrayForSheet = [
    ['key', ...locales],
    ...sortBy(Object.keys(data)).map((translationKey) => [
      translationKey,
      ...locales.map((locale) => get(data[translationKey], locale, '')),
    ]),
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(arrayForSheet);
  wb.SheetNames.push('output_translations');
  wb.Sheets['output_translations'] = worksheet;
  wb.SheetNames.push('input');
  wb.SheetNames.push('output');
  wb.Sheets['output'] = XLSX.utils.aoa_to_sheet([
    ['translations', 'multi:translations'],
  ]);
  const workbookOut = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  saveAs(
    new Blob([s2ab(workbookOut)], { type: 'application/octet-steam' }),
    'translations.xlsx',
  );
};
