import React from 'react';
import Card from 'components/ui/card';
import { useI18n } from 'services/i18n/hook';
import WorkflowSelector from 'components/workflow/selector';
import { useWorkflow } from 'services/workflow/hook';
import Loader from 'components/ui/loader';
import EmptyState from 'components/ui/empty_state';
import WorkflowTranslationEmpty from 'images/workflow_translation_empty.svg';
import Flow from 'components/workflow/flow/flow';

const WorkflowFlow = () => {
  const { t } = useI18n();
  const { selectedWorkflow, loading } = useWorkflow();
  return (
    <Card
      title={<h5>{t('components.workflow.flow.index.flow')}</h5>}
      actions={<WorkflowSelector />}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {selectedWorkflow && selectedWorkflow.workflow.id ? (
            <Flow />
          ) : (
            <EmptyState
              image={WorkflowTranslationEmpty}
              text={t(
                'components.workflow.flow.index.select_a_workflow_to_see_flow',
              )}
            />
          )}
        </>
      )}
    </Card>
  );
};

export default WorkflowFlow;
