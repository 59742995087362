import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationTop from 'components/navigation/top';

const AuthenticatedRoot = () => {
  return (
    <div className="container-fluid">
      <div className="content">
        <NavigationTop />
        <Outlet />
      </div>
    </div>
  );
};

export default AuthenticatedRoot;
