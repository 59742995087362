import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaContext from 'services/recaptcha/context';

const RecaptchaProvider = ({ children }) => {
  const googleReCaptcha = useGoogleReCaptcha();
  const { executeRecaptcha } = googleReCaptcha;
  const getCaptchaToken = (action) => {
    return executeRecaptcha(action);
  };

  return (
    <RecaptchaContext.Provider
      value={{
        getCaptchaToken,
      }}
    >
      {children}
    </RecaptchaContext.Provider>
  );
};

export default RecaptchaProvider;
