import React, { useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'quill-paste-smart';
import 'react-quill/dist/quill.snow.css';
import { useI18n } from 'services/i18n/hook';
import Button from 'components/ui/button';
import copy from 'clipboard-copy';

import { toast } from 'react-toastify';

const notify = (text) => {
  toast.success(text, {
    position: 'bottom-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'colored',
  });
};

const ScenesWorkflowHTMLGenerator = () => {
  const { t } = useI18n();
  const [body, setBody] = useState('');
  const copyGeneratedHTML = () => {
    copy(body).then(() => notify('Copied !'));
  };
  return (
    <Row className="g-3 mb-3">
      <Col md={8}>
        <Card>
          <Card.Header className="bg-light">
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <div>
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {t('scenes.workflow.html_generator.write_content')}
                  </h5>
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2" style={{ height: '500px' }}>
            <ReactQuill
              theme="snow"
              value={body}
              style={{ height: '400px' }}
              onChange={(newTemplate) => {
                setBody(newTemplate);
              }}
            />
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Card>
          <Card.Header className="bg-light">
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <div>
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                    {t('scenes.workflow.html_generator.generated_html')}
                  </h5>
                </div>
              </Col>
              <Col className="ml-auto">
                {body && (
                  <Button size="sm" onClick={copyGeneratedHTML}>
                    {t('scenes.workflow.html_generator.copy')}
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-2">{body && JSON.stringify(body)}</Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ScenesWorkflowHTMLGenerator;
