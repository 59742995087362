import React from 'react';
import Section from 'components/ui/theme/components/common/Section';
import { Row, Col, Card } from 'react-bootstrap';
import Logo from 'components/ui/logo';

const ScenesError500 = (props) => {
  console.log('props', props);
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
          <Logo width={200} />
          <Card className="text-center h-100">
            <Card.Body className="p-5">
              <div className="display-1 text-300 fs-error">500</div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                Whoops, something went wrong!
              </p>
              <hr />
              <p>
                Try refreshing the page, or going back and attempting the action
                again. If this problem persists,
                <a href="mailto:info@exmaple.com" className="ms-1">
                  contact us
                </a>
                .
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};

export default ScenesError500;
