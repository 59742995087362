import React from 'react';
import { Button as BsButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Button = ({ icon, children, ...restProps }) => {
  if (!icon) {
    return <BsButton {...restProps}>{children}</BsButton>;
  }

  return (
    <BsButton {...restProps}>
      <span className="d-flex align-items-center">
        <FontAwesomeIcon icon={icon} className="me-2" />
        {children}
      </span>
    </BsButton>
  );
};

export default Button;
